import React from 'react';
import PropTypes from 'prop-types';

import styles from './Reason.module.scss';

const Reason = props => (
  <div className={styles['reason']}>
    <span className={styles['reason__number']}>{props.number}</span>
    <div>
      <h6 className={styles['reason__title']}>{props.title}</h6>
      <p className={styles['reason__description']}>{props.description}</p>
    </div>
  </div>
);

Reason.propTypes = {
  number: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string
};

export default Reason;