import React, { useState, useEffect, Fragment } from 'react';
import axios from "axios";

import styles from './index.module.scss';

import FormatDate from '../../../../../components/FormatDate/FormatDate'
import ContentCMS from "../../../../../components/ContentCMS/ContentCMS";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll/modules";
import Breadcrumbs from "../../../../../components/Breadcrumbs/Breadcrumbs";
import Head from "../../../../../layout/Head/Head";
import Container from "../../../../../hoc/Container/Container";
import Loading from "../../../../../components/Loading/Loading";

const PressArticlePage = props => {
  const [pressArticle, setPressArticle] = useState({});

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  useEffect(() => {
    const slug = props.match.params['slug'];
    axios.get(`${process.env.REACT_APP_API_URL}/api/collections/get/press?filter[title_slug]=${slug}`)
      .then(response => {
        setPressArticle(response.data.entries[0])
      })
      .catch(error => console.log('Error', error));
  }, []);

  return (
    <Fragment>
      {pressArticle._id ?
        <Fragment>
          <Head
            title={pressArticle.title}
            image={`${process.env.REACT_APP_API_URL}${pressArticle.cover.path}`}
          />
          <Container>
            <Breadcrumbs {...props} currentPositionText={pressArticle.title} />

            <article className={styles['article']}>
              <header className={styles['article-header']}>
                <div className={styles['article-info']}>
                  <div className={styles['article-info__logo-container']}>
                    <img src={`${process.env.REACT_APP_API_URL}/${pressArticle.logo && pressArticle.logo.path}`}
                      alt="logo"
                      className={styles['article-info__logo']} />
                  </div>
                  <FormatDate format="MMM Do YYYY" date={pressArticle.date}
                    className={styles['article-info__date']} />
                </div>
                <h1 className={styles['article__title']}>
                  {pressArticle.title}
                </h1>

                <div className={styles['article__description-container']}>
                  <p className={styles['article__description']}>{pressArticle.description}</p>
                </div>
              </header>

              <ContentCMS data={pressArticle.content} />

              {pressArticle.fullArticle ?
                <a href={pressArticle.fullArticle} rel="noopener noreferrer" target="_blank" className={styles['article-actions__link']}
                  style={{ marginTop: '2.5rem' }}>
                  <i className="material-icons">open_in_new</i>
                  READ FULL ARTICLE
                </a> : undefined
              }
              <div className={styles['article-actions']}>
                <Link to="/press" className={styles['article-actions__link']}>
                  <i className="material-icons">arrow_back</i>
                  Back to All Articles
                </Link>
                <span onClick={scrollToTop} className={styles['article-actions__link']}>
                  <i className="material-icons">arrow_upward</i>
                  Go to Top
                </span>
              </div>
            </article>
          </Container>
        </Fragment>
        :
        <Loading />
      }
    </Fragment>
  );
};

export default PressArticlePage;