import React from 'react';
import styles from './Container.module.scss'

// Used to center the content
// By default the max width is 64rem. If size='small' is passed the max width will be equal to 42.5rem
const Container = props => {
  return (
    <div className={styles['container']}
         style={{maxWidth: props.size === 'small' ? '42.5rem' : '64rem'}}
    >
      {props.children}
    </div>
  )
};

export default Container;
