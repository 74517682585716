import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Container from "../../../../../hoc/Container/Container";
import Grid from "../../../../../hoc/Grid/Grid";
import OpenPosition from "../OpenPosition/OpenPosition";

import styles from './OpenPositionList.module.scss';

const OpenPositionsList = props => {
  const [positions, setPositions] = useState(props.positions);
  const [departments, setDepartments] = useState(props.departments);
  const [locationFilter, setLocationFilter] = useState('all');
  const [departmentFilter, setDepartmentFilter] = useState('all');

  useEffect(() => {
    if (locationFilter === 'all') {
      setDepartments(props.departments);
    } else {
      setDepartments(locationFilter.departments);
    }

    if (departmentFilter === 'all') {
      if (locationFilter === 'all') {
        setPositions(props.positions);
      } else {
        setPositions(locationFilter.departments.reduce((acc, currentDept) => {
          return acc.concat(...currentDept.jobs);
        }, []));
      }
    } else {
      setPositions(departmentFilter.jobs);
    }

  }, [locationFilter, departmentFilter]);

  const onChangeLocation = event => {
    if (event.target.value === 'all') {
      setLocationFilter('all');
    } else {
      setLocationFilter(props.locations[event.target.value]);

      if (departmentFilter !== 'all') {
        const departmentExists = props.locations[event.target.value].departments.filter(dept => dept.id === departmentFilter.id).length > 0;
        if (!departmentExists) {
          setDepartmentFilter('all');
        }
      }
    }
  };

  const onChangeDepartment = event => {
    if (event.target.value === 'all') {
      setDepartmentFilter('all');
    } else if (locationFilter === 'all') {
      setDepartmentFilter(props.departments[event.target.value])
    } else {
      setDepartmentFilter(locationFilter.departments[event.target.value])
    }
  };

  // sort positions
  positions.sort((a, b) => (a.title > b.title) ? 1 : -1);

  return (
    <section className={styles['open-position-list']} id="open-positions-list">
      <Container>
        <div className={styles['open-position-list__select-container']}>
          <div className={styles['open-position-list__select-item']}>
            <select className={styles['open-position-list__select']}
                    onChange={onChangeLocation}
            >
              <option value="all">All Locations</option>
              { props.locations.map((location, index) => <option key={location.id} value={index}>{location.name}</option>) }
            </select>
            <div className={styles['select-decoration']}/>
          </div>

          <div className={styles['open-position-list__select-item']}>
            <select className={styles['open-position-list__select']}
                    onChange={onChangeDepartment}
            >
              <option value="all">All Departments</option>
              { departments.map((department, index) => <option key={department.id} value={index}>{department.name}</option>) }
            </select>
            <div className={styles['select-decoration']}/>
          </div>
        </div>
        <Grid>
          {positions.map(position => (
            <OpenPosition key={position.id}
                          title={position.title}
                          description={position.location.name}
                          jobId={position.id}
            />
          ))}
        </Grid>
      </Container>
    </section>
  );
};

OpenPositionsList.propTypes = {
  positions: PropTypes.array.isRequired,
  departments: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired
};

export default OpenPositionsList;
