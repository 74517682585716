import React, { Component } from 'react';
import * as styles from "./HightlightsSection.module.scss";
import { Link } from "react-router-dom";

class HighlightsSection extends Component {
  componentDidMount() {
    // Adding our Highlights Unit
    const script = document.createElement("script");
    script.id = "2935d5053d9145b18a3033f78c7b8180";
    script.innerText = (`cnx.cmd.push(function() { cnx({ playerId: "8750dad6-b85d-4acc-892c-5fb9815244cb" }).render("2935d5053d9145b18a3033f78c7b8180"); });`);

    const highlightsContainer = document.getElementById('highlights');
    highlightsContainer.appendChild(script);
  }

  render() {
    return (
      <section className={styles['homepage-highlights']} id="homepage-highlights">
        <div className={styles['homepage-container']}>
          <div className={styles['homepage-highlights-container']}>
            <div className={styles['homepage-highlights__content']}>
              {/* <div className={styles['homepage-highlights__scroll-section']}>
                <ScrollSection playspaceActive={true}/>
              </div> */}
              <div className={styles['section-tag']}>For Publishers</div>
              <h2 className={styles['homepage-section__title']}>
                Grow video revenue without the excessive player fees              </h2>
              <p className={styles['homepage-section__subtitle']}>
              Access a robust monetization engine and direct DSP integrations to increase demand and empower direct sales teams. </p>
              <Link to="/video-monetization" className={styles['homepage-highlights__link']}>Learn More</Link>
            </div>
            <div className={styles['homepage-highlights__img-container']}>
              <div className={styles['homepage-highlights__player']}>
                <div id="highlights" />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default HighlightsSection;
