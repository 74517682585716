import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import './DesktopMenu.scss';


class DesktopMenu extends Component {
  constructor(props) {
    super(props);

    // create a ref to store the textInput DOM element
    this.menu = React.createRef();
  }
  renderMenu = (menuItems) => {
    return menuItems.map((item, index) => (
      <div className='menu' key={index}
        ref={this.menu}
        onMouseEnter={this.toggleMenuHeaderActive}
        onMouseLeave={this.toggleMenuHeaderActive}>
        <div className='menu-header' id={item.category}>
          {item.categoryPath ?
            <span className='menu-header-text' onClick={this.setMenuHeaderActive}>
              <NavLink to={item.categoryPath} className='menu-header-link' activeClassName='menu-link--active'>{item.category}</NavLink>
            </span>
            : <span className='menu-header-text'>{item.category}</span>}
        </div>

        {!item.categoryPath && item.links ?
          <ul className='menu-section'>
            {item.links.map((link, index) => (
              <li key={index} onClick={this.setMenuHeaderActive}>
                <NavLink to={link.path} className='menu-link' activeClassName='menu-link--active'>{link.text}</NavLink>
              </li>
            ))}
          </ul> :
          undefined}
      </div>
    ))
  };

  toggleMenuHeaderActive = (elem) => {
    elem.currentTarget.childNodes[0].classList.toggle('menu-header-hover');
  };

  setMenuHeaderActive = (elem) => {
    const navigation = this.menu.current.parentElement.childNodes;

    navigation.forEach(element => {
      element.childNodes[0].classList.remove('menu-header-active');
    });

    elem.currentTarget.closest('.menu-header') ? elem.currentTarget.closest('.menu-header').classList.add('menu-header-active') :
      elem.currentTarget.parentElement.previousElementSibling.classList.add('menu-header-active');
  };

  componentDidMount() {
    const activeLink = document.getElementsByClassName('menu-link--active');

    if (activeLink[0]) {
      activeLink[0].closest('.menu-header') ?
        activeLink[0].closest('.menu-header').classList.add('menu-header-active') :
        activeLink[0].parentElement.parentElement.previousElementSibling.classList.add('menu-header-active');
    }
  }

  render() {
    return (
      <div className='desktop-nav'>
        {this.renderMenu(this.props.navigationLinks)}
      </div>
    );
  }
}

export default DesktopMenu;