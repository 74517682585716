import React from 'react'
import Container from "../../hoc/Container/Container";

import * as styles from './index.module.scss';

import tyDecoration from '../../assets/thank-you/thank-you-decoration.png';
import Head from "../../layout/Head/Head";

const ThankYouPage = () => {
  return (
    <div className={styles['thank-you-container']}>
      <Head title="Connatix - Thank You" />
      <Container>
        <img src={tyDecoration} alt="" className={styles['thank-you-decoration']}/>
        <h1 className={styles['thank-you__title']}>Thank you for reaching out!</h1>
        <p className={styles['thank-you__description']}>
          Our team will get back to you with lightning speed.
        </p>
      </Container>
    </div>
  )
};


export default ThankYouPage;