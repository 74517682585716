import React, { useState, useEffect } from 'react';
import Head from '../../../layout/Head/Head';
import axios from "axios";

import ContactFormSection from "../../../components/ContactFormSection/ContactFormSection";
import TwoColumnsTextSection from "../../../hoc/TwoColumnsTextSection/TwoColumnsTextSection";
import TwoRowsTextSection from "../../../hoc/TwoRowsTextSection/TwoRowsTextSection";

import section1Image from '../../../assets/adserver/adserver_section1.png';
import section2Image from '../../../assets/adserver/adserver_section2.png';
import section4Image from '../../../assets/adserver/adserver_section4.png';
import section4ImageMobile from '../../../assets/adserver/adserver_section4_mobile.png';

import section1DecorativeImage from '../../../assets/adserver/adserver_decorative_section1.png';
import section2DecorativeImage from '../../../assets/adserver/adserver_decorative_section2.png';
import section4DecorativeImage from '../../../assets/adserver/adserver_decorative_section4.png';
import formDecoration from '../../../assets/contact/contact-form-decoration-purple.png';
import Carousel from '../../../hoc/Carousel/Carousel';
import QuotesCarousel from '../../../components/QuotesCarousel/QuotesCarousel';
import * as styles from './index.module.scss';

import AdServerHeader from './components/AdServerHeader/AdServerHeader';

const section1DecorativeImageId = 'section1__decorative';
const section1DecorativeImageStyle = `
  #${section1DecorativeImageId} {
    max-width: 190px;
    min-width: 80px;
    width: 15%;
    bottom: -75px;
    right: 1%;
  }

  @media only screen and (min-width: 48rem) {
    #${section1DecorativeImageId} {
      bottom: -95px;
    }
  }

  @media only screen and (min-width: 62rem) {
    #${section1DecorativeImageId} {
      width: 30%;
      bottom: -30%;
    }
  }

  @media only screen and (min-width: 75rem) {
    #${section1DecorativeImageId} {
      right: -10%;
    }
  }
`;

const section2DecorativeImageId = 'section2__decorative';
const section2DecorativeImageStyle = `
  #${section2DecorativeImageId} {
    max-width: 120px;
    min-width: 65px;
    width: 10%;
    bottom: -85px;
    left: 1%;
  }

  @media only screen and (min-width: 48rem) {
    #${section2DecorativeImageId} {
      width: 10%;
      bottom: -95px;
    }
  }

  @media only screen and (min-width: 62rem) {
    #${section2DecorativeImageId} {
      width: 30%;
      bottom: -35%;
    }
  }

  @media only screen and (min-width: 75rem) {
    #${section2DecorativeImageId} {
      left: -15%;
    }
  }
`;

const section4DecorativeImageId = 'section4__decorative';
const section4DecorativeImageStyle = `
  #${section4DecorativeImageId} {
    max-width: 170px;
    min-width: 100px;
    width: 20%;
    top: -90px;
    right: 1%;
  }

  @media only screen and (min-width: 48rem) {
    #${section4DecorativeImageId} {
      width: 15%;
      top: -120px;
    }
  }

  @media only screen and (min-width: 62rem) {
    #${section4DecorativeImageId} {
      width: 30%;
      top: -32%;
    }
  }

  @media only screen and (min-width: 75rem) {
    #${section4DecorativeImageId} {
      right: -5%;
    }
  }
`;

// const section2PlayerScript = `!function(n){if(!window.cnxps){window.cnxps={},window.cnxps.cmd=[];var t=n.createElement('iframe');t.display='none',t.onload=function(){var n=t.contentWindow.document,c=n.createElement('script');c.src='//cd.connatix.com/connatix.playspace.js',c.setAttribute('async','1'),c.setAttribute('type','text/javascript'),n.body.appendChild(c)},n.head.appendChild(t)}}(document);
//                               cnxps.cmd.push(function () { cnxps({ playerId: "af955a40-595d-43b5-a38a-d4c48672cffb" }).render("956caf8764c5411692b9a8056cab2e6e");});`;
// const section2PlayerScriptId = '956caf8764c5411692b9a8056cab2e6e';
// const section2PlayerStyles = ".player {margin: 8% -8%;}";


const AdServerPage = props => {
  const formID = '6e0234e5-83c1-43c0-94ab-9cf4e3d335fb';
  const sectionOptions = {
    imageBackgroundMargin: "8% 0% 8% -4%",
    backgroundColor: "#EFF3F5",
    direction: 'row-reverse'
  }
  const [adServerCarousel, setAdServerCarousel] = useState([]);
  const [quotes, setQuotes] = useState([]);
  const [quotesSectionTitle, setQuotesSectionTitle] = useState();
  const [quotesSectionBgColor, setQuotesSectionBgColor] = useState();

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/collections/get/AdServerPageFeaturedItemsCarousel`)
      .then(response => {
        setAdServerCarousel(response.data.entries ? response.data.entries : [])
      });

    axios.get(`${process.env.REACT_APP_API_URL}/api/collections/get/adserverQuotesCarousel`)
      .then(response => {
        setQuotes(response.data.entries ? response.data.entries : [])
        if (response.data.entries) {
          //Set Quote Section Background Color
          let entryWBgColor = response.data.entries.find(entry => entry.backgroundColor);
          setQuotesSectionBgColor(entryWBgColor.backgroundColor);
          //Set Quote Section Title
          let entryWTitle = response.data.entries.find(entry => entry.sectionTitle);
          setQuotesSectionTitle(entryWTitle.sectionTitle);
        }

      })
      .catch(error => console.log('Quotes Carousel Error: ', error));
  }, []);
  return (
    <div className={styles['overflow-hidden-container']}>
      <Head title="Connatix - Video Analytics" />
      <AdServerHeader {...props} />

      <main>
        {/* Section 1 */}
        <section>
          <TwoColumnsTextSection
            image={section1Image}
            imageBackgroundMargin="8% -4%"
            direction="row-reverse"
            backgroundColor="#EFF3F5"
            decorativeImage={section1DecorativeImage}
            decorativeImageId={section1DecorativeImageId}
            decorativeImageStyle={section1DecorativeImageStyle}
            hasPaddingBottom={true}
            descriptionDecorationColor="#FFCC00"
            description="Explore data via dynamic dashboards"
            info="Get on-demand analytics for:"
            bulletList={["Revenue and player metrics", "Content KPIs", "Advertising performance"]}            
          />
        </section>

        {/* Section 2 */}
        <section style={{paddingBottom: '96px'}}>
          <TwoColumnsTextSection
            // playerScript={section2PlayerScript}
            // playerScriptId={section2PlayerScriptId}
            // playerStyles={section2PlayerStyles}
            image={section2Image}
            imageBackgroundMargin="4% -4%"
            direction="row"
            backgroundColor="#FFFFFF"
            decorativeImage={section2DecorativeImage}
            decorativeImageId={section2DecorativeImageId}
            decorativeImageStyle={section2DecorativeImageStyle}
            descriptionDecorationColor="#FFCC00"
            description="Easily access analytics with extensive reporting tools"
            bulletList={["Multi-dimensional reporting for sophisticated data analysis", "Reporting API to customize, run, and receive reports from the Connatix platform", "Integrated reporting plugins, including MOAT and Adobe Omniture", "Custom reporting dimensions", "Automated report scheduling and distribution"]}            
          />
        </section>

        {/* Section 3 - Carousel */}
        <Carousel carouselItems={adServerCarousel} sectionOptions={sectionOptions}></Carousel>

        {/* Section 4 */}
        <section>
          <TwoRowsTextSection
            image={section4Image}
            imageMobile={section4ImageMobile}
            imageMargin="2% 0 0 0"
            imageBackgroundMargin="6% 0 0"
            direction="column"
            backgroundColor="#FFFFFF"
            descriptionDecorationColor="#FFCC00"
            decorativeImage={section4DecorativeImage}
            decorativeImageId={section4DecorativeImageId}
            decorativeImageStyle={section4DecorativeImageStyle}
            hasPaddingBottom={true}
            description="Gain contextual insights with in-video analysis"
            info="Unlock contextual data points to inform and support editorial strategies through frame-by-frame visual and audio analysis"
            buttonText="LEARN MORE" 
            buttonLink="/deep-contextual"
          />
        </section>

        {/*Quotes */}
        <section className={`quotes-section ${quotesSectionBgColor === 'white' ? 'bg-white' : 'bg-gray'}`}>
          <QuotesCarousel quotes={quotes} quotesSectionTitle={quotesSectionTitle} />
        </section>

        {/* Contact form */}
        <ContactFormSection id="adserver-contact"
          title="Ready to streamline your AdOps strategy?"
          titleDecorationColor='#F2DC4F'
          description="Reach out to learn more."
          formID={formID}
          formDecoration={formDecoration}
        />
      </main>
    </div>
  )
};

export default AdServerPage;