import React from 'react';
import PropTypes from 'prop-types';

import styles from './CoverCard.module.scss';

import FormatDate from "../FormatDate/FormatDate";
import TextClamp from "../TextClamp/TextClamp";
import { Link } from "react-router-dom";

import cardOverlay from '../../assets/cards-gradient.png';

const CoverCard = props => (
  <div className={styles['cover-card']} style={props.hasPadding ? {'padding': '16px'} : undefined}>
    <Link to={`${props.url}/${props.title_slug}`} className={styles['cover-card-link']}>
      <div className={styles['cover-card__cover-container']}>
        <div className={styles['cover-card__cover']}
             style={{backgroundImage: `url(${process.env.REACT_APP_API_URL}/${props.cover.path})`}}/>
      </div>
      <div className={styles['cover-card__content']}>
        <div className={styles['cover-card__details']}>
          <FormatDate format="MMM Do YYYY" date={props.date} className={styles['details__date']}/>
        </div>

        <TextClamp
          text={props.title}
          lines={3}
          ellipsis="..."
          className={styles["cover-card__title"]}
        />

        <p className={styles["cover-card__description"]}>
          {props.description}
        </p>
      </div>
      <span className={styles["cover-card__button"]}>{props.buttonText}</span>
      <img src={cardOverlay} alt="" className={styles["cover-card--overlay"]}/>
    </Link>

  </div>
);

CoverCard.propTypes = {
  url: PropTypes.string.isRequired,
  title_slug: PropTypes.string,
  hasPadding: PropTypes.bool
};

export default CoverCard;