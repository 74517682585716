import React, { Fragment, useState, useEffect } from 'react';
import axios from "axios";
import Head from "../../../../../layout/Head/Head";
import Container from "../../../../../hoc/Container/Container";
import Breadcrumbs from "../../../../../components/Breadcrumbs/Breadcrumbs";
import styles from "./index.module.scss";
import ContentCMS from "../../../../../components/ContentCMS/ContentCMS";
import Loading from "../../../../../components/Loading/Loading";

const ManageCookiesPage = props => {
  const [manageCookies, setManageCookies] = useState([]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/singletons/get/manageCookies`)
      .then(response => {setManageCookies(response.data.content)})
      .catch(error => console.log('error', error));
  }, []);

  return (
    <Fragment>
      {manageCookies.length ?
        <Fragment>
          <Head title='Connatix - Manage Cookies' />
          <Container>
            <Breadcrumbs {...props} currentPositionText='Manage Cookies'/>
            <main className={styles['manage-cookies']}>
              <ContentCMS data={manageCookies}/>
            </main>
          </Container>
        </Fragment>
        :
        <Loading/>
      }
    </Fragment>
  )
};

export default ManageCookiesPage;