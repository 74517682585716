import React, { Component } from 'react';
import { Tabs, Tab } from 'react-tab-view';
import './Experiences.scss';
import TwoRowsTextSection from "../../../../../hoc/TwoRowsTextSection/TwoRowsTextSection";

import slide1 from '../../../../../assets/elements/slides/elements_section2_slide1.png';
import slide2 from '../../../../../assets/elements/slides/elements_section2_slide2.png';
import slide3 from '../../../../../assets/elements/slides/elements_section2_slide3.png';
import slide4 from '../../../../../assets/elements/slides/elements_section2_slide4.png';
import slide5 from '../../../../../assets/elements/slides/elements_section2_slide5.png';

import decorativeImage from '../../../../../assets/elements/elements_decorative_section2.png';
const decorativeImageId = 'experiences__decorative';
const decorativeImageStyle = `
  #${decorativeImageId} {
    max-width: 215px;
    width: 30%;
    bottom: -10%;
    right: 1%;
  }

  @media only screen and (min-width: 48rem) {
    #${decorativeImageId} {
      width: 25%;
      bottom: -12%;
    }
  }

  @media only screen and (min-width: 62rem) {
    #${decorativeImageId} {
      bottom: -20%;
      right: -5%;
    }
  }
`;

export default class Experiences extends Component {
  constructor(props) {
    super(props);

    this.state = {
      headerLinks: [],
      experiencesIndex: 0
    }
  }

  componentDidMount() {
    const tabView = document.getElementById('experiences-section').getElementsByClassName('iMnhxj');
    const tabViewHeader = tabView[0].getElementsByTagName('div')[0];
    const tabViewLinks = [...tabViewHeader.getElementsByTagName('a')];

    this.setState({
      headerLinks: tabViewLinks
    });

    this.interval = setInterval(this.onNavigateCarousel, 5000);

    // Update index from state with the index of the clicked element
    for(let i = 0; i < tabViewLinks.length; i++) {
      tabViewLinks[i].addEventListener('click', () => {
        clearInterval(this.interval);

        this.setState({
          experiencesIndex: tabViewLinks.indexOf(tabViewLinks[i])
        });
        this.interval = setInterval(this.onNavigateCarousel, 5000);
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  onNavigateCarousel = () => {
    if (this.state.experiencesIndex >= this.state.headerLinks.length - 1) {
      this.setState({
        experiencesIndex: 0
      });
    } else {
      this.setState({
        experiencesIndex: this.state.experiencesIndex + 1
      })
    }
    this.state.headerLinks[this.state.experiencesIndex].click();
  };

  render() {
    return(
      <div id="experiences-section">
        <TwoRowsTextSection
          imageBackgroundMargin="4% -4%"
          direction="column"
          backgroundColor="#FFFFFF"
          decorativeImage={decorativeImage}
          decorativeImageId={decorativeImageId}
          decorativeImageStyle={decorativeImageStyle}
          hasPaddingBottom={true}
          descriptionDecorationColor="#FFCC00"
          description="Stay on-brand with player customizations"
          info="Control player aesthetics and behavior, giving readers the seamless video they expect and editors the tools they need."
        >
          <Tabs headers={experiencesHeaders[0]}>
            {experiencesData.map((item, index) => (
              <Tab key={index}>
                <div className='experiences-tab'>
                  <img src={item.element.image} alt="" className="experiences-tab__img"/>
                  <p className="experiences-tab__text">{item.element.text}</p>
                </div>
              </Tab>
            ))}
          </Tabs>
        </TwoRowsTextSection>
        </div>
    );
  }
}

const experiencesData = [
  {
    title: 'Custom Logo',
    element: {
      text: 'Amplify your brand presence by personalizing your player with a logo or symbol.',
      image: slide1
    }
  },
  {
    title: 'Fonts & Colors',
    element: {
      text: 'Customize the fonts and colors of your player, keeping it not only on-brand but also seamless for your readers.',
      image: slide2
    }
  },
  {
    title: 'Auto-Play',
    element: {
      text: 'Select the player’s behavior to enhance your user experience - choose from autoplay, scroll to play, or click to play.',
      image: slide3
    }
  },
  {
    title: 'Sound On/Off',
    element: {
      text: 'Choose if your player’s sound should start automatically upon page load or when readers click to engage with your content.',
      image: slide4
    }
  },
  {
    title: 'Ad Scheduling',
    element: {
      text: 'Control the monetization opportunities of your player by choosing ad slots and frequency.',
      image: slide5
    }
  }
];

const experiencesHeaders = new Array(experiencesData.map(item => item.title));