import React, { Fragment, useState, useEffect } from 'react';
import axios from "axios";

import styles from './index.module.scss';

import ContentCMS from "../../../components/ContentCMS/ContentCMS";
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs";
import Container from "../../../hoc/Container/Container";
import Head from "../../../layout/Head/Head";
import Loading from "../../../components/Loading/Loading";

const JobApplicantPrivacyPolicyPage = props => {
  const [jobApplicantPrivacyPolicy, setJobApplicantPrivacyPolicy] = useState([]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/singletons/get/jobApplicantPrivacyPolicy`)
      .then(response => {
        setJobApplicantPrivacyPolicy(response.data.content ? response.data.content : []);
      })
      .catch(error => console.log('error', error));
  }, []);

  return (
    <Fragment>
      {jobApplicantPrivacyPolicy.length ?
        <Fragment>
          <Head title='Connatix - Job Applicant Privacy Policy' />
          <Container>
            <Breadcrumbs {...props} currentPositionText='Job Applicant Privacy Notice'/>
            <main className={styles['privacy-policy']}>
              <ContentCMS data={jobApplicantPrivacyPolicy}/>
            </main>
          </Container>
        </Fragment>
        :
        <Loading/>
      }
    </Fragment>
  );
};

export default JobApplicantPrivacyPolicyPage;
