import React from 'react';
import PropTypes from 'prop-types';

import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";

import './GalleryCarousel.scss';

import topDecoration from '../../../../../assets/careers/carousel-decoration-top.png'
import bottomDecoration from '../../../../../assets/careers/carousel-decoration-bottom.png'

const GalleryCarousel = ({gallery}) => {
  const responsive = {
    767: { items: 3 }
  };

  return (
    <div className="careers-carousel-container">
      <img src={topDecoration} alt="" className="carousel__top-decoration"/>
      <AliceCarousel
        autoPlay={true}
        autoPlayInterval={3000}
        infinite={true}
        disableButtonsControls
        responsive={responsive}
      >
        {gallery.map((image, index) => (
          <img key={index}
               src={`${process.env.REACT_APP_API_URL}${image.path}`}
               alt=""
               style={{borderRadius: '4px'}}
          />
        ))}
      </AliceCarousel>
      <img src={bottomDecoration} alt="" className="carousel__bottom-decoration"/>
    </div>
  )
}

/*

import "react-responsive-carousel/lib/styles/carousel.min.css";
import './GalleryCarousel.scss';
import { Carousel } from "react-responsive-carousel";


const GalleryCarousel = ({gallery}) => {
  const [innerWidth, setInnerWidth] = useState(0);
  const [centerPercentage, setCenterPercentage] = useState(0);

  useEffect(() => {
    if (innerWidth === 0) {
      setInnerWidth(window.innerWidth);
      setCenterPercentage(carouselPercentage(window.innerWidth))
    }

    window.addEventListener('resize', e => {
      setInnerWidth(e.target.innerWidth);
      setCenterPercentage(carouselPercentage(e.target.innerWidth));
    })
  }, []);

  const carouselPercentage = value => {
    if (value < 768) {
      return 100
    } else if (value < 992) {
      return 70
    } else {
      return 36
    }
  };
  return (
    <div className="careers-carousel-container">
      {innerWidth && centerPercentage ?
        <Carousel
          autoPlay={true}
          showStatus={false}
          showArrows={false}
          infiniteLoop={true}
          interval={2000}
          transitionTime={1000}
          centerMode={true}
          centerSlidePercentage={centerPercentage}
          showThumbs={false}>
          {gallery.map((image, index) => (
            <div key={index} style={{padding: innerWidth < 768 ? 0 : '0 8px'}}>
              <img
                src={`${process.env.REACT_APP_API_URL}${image.path}`}
                alt=""
                style={{borderRadius: '4px'}}
              />
            </div>
          ))}
        </Carousel>
        : undefined
      }
    </div>
  )
};
*/


GalleryCarousel.propTypes = {
  gallery: PropTypes.array.isRequired
};

export default GalleryCarousel;
