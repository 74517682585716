import React, { Fragment, useState, useEffect } from 'react';
import axios from "axios";

import Breadcrumbs from "../../../../../components/Breadcrumbs/Breadcrumbs";
import styles from './index.module.scss';
import { Link } from "react-router-dom";
import { animateScroll as scroll } from 'react-scroll';
import ContentCMS from "../../../../../components/ContentCMS/ContentCMS";
import Container from "../../../../../hoc/Container/Container";
import Loading from "../../../../../components/Loading/Loading";
import Head from "../../../../../layout/Head/Head";
import ArticleHeader from "./components/article-header/ArticleHeader";

const BlogArticlePage = props => {
  const [blogArticle, setBlogArticle] = useState({});

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  useEffect(() => {
    const slug = props.match.params['slug'];
    axios.get(`${process.env.REACT_APP_API_URL}/api/collections/get/blog?filter[title_slug]=${slug}`)
      .then(response => {
        setBlogArticle(response.data.entries[0]);
      })
      .catch(error => console.log('Error', error));
  }, []);

  return (
    <Fragment>
      {blogArticle._id ?
        <Fragment>
          <Head
            title={blogArticle['seoTitle']}
            description={blogArticle['seoDescription']}
            image={`${process.env.REACT_APP_API_URL}${blogArticle.cover.path}`}
          />
          <Container>
            <Breadcrumbs {...props} currentPositionText={blogArticle.title} hasBackground={false} />
          </Container>
          <Container size={'small'}>
            <ArticleHeader articleTitle={blogArticle.title}
              articleDate={blogArticle.date}
              articleCover={blogArticle.cover && blogArticle.cover.path}
              articleDescription={blogArticle.description}
              authorAvatar={blogArticle['authorAvatar'] && blogArticle['authorAvatar'].path}
              authorName={blogArticle['authorName']}
              authorPosition={blogArticle['authorPosition']} />
            <ContentCMS data={blogArticle.content} />
            <div className={styles['article-actions']}>
              <Link to="/blog" className={styles['article-actions-link']}>
                <i className="material-icons">arrow_back</i>
                Back to All Articles
              </Link>
              <span onClick={scrollToTop} className={styles['article-actions-link']}>
                <i className="material-icons">arrow_upward</i>
                  Go to Top
                  </span>
            </div>
          </Container>
        </Fragment>
        :
        <Loading />
      }
    </Fragment>
  );
};

export default BlogArticlePage;
