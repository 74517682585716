import React, { useState, useEffect } from "react";


import './CommonQuestions.scss';
import icCaretDown from '../../../../assets/svg/ic_caret_down.svg';
import icCaretUp from '../../../../assets/svg/ic_caret_up.svg';
import axios from "axios";
import commonQuestionsDecoration from "../../../../assets/svg/decoration.svg";
import { Link } from "react-scroll/modules";

// const cardsPerPage = 3;
// let arrayForHoldingCards = [];

const CommonQuestions = () => {



    const [cards, setCards] = useState([]);
    const [cardVisible, setCardVisible] = useState({});
    const [cardsToShow, setCardsToShow] = useState([]);
    const [nextCards, setNextCards] = useState(4);
    const [isReadMoreVisible, setIsReadMoreVisible] = useState(false);

    let displayedEntries = [];
    let initialBatchNumber = 4; //Must be the same as the default nextCards number set above in useState(); the number of cards we want to show when the component loads. This number could be dynamic through a prop
    let extraBatchNumber = 1; //the number of cards to load when clicking Read More.  This number could be dynamic through a prop

    const isCardVisible = (index) => cardVisible[index];
    const toggleCardVisibility = (index) => () => {
        setCardVisible({
            ...cardVisible,
            [index]: !isCardVisible(index)
        })
    };

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/collections/get/commonQuestions`)
            .then(response => {
                if (response.data.entries) {
                    const entries = response.data.entries;
                    displayedEntries = entries.slice(0, initialBatchNumber);
                    setCards(response.data.entries);
                    setCardsToShow(displayedEntries);

                    //set the visibility of the Read More button
                    if(displayedEntries.length === response.data.entries.length) {
                        setIsReadMoreVisible(false);
                    } else {
                        setIsReadMoreVisible(true);

                    }
                } else {
                    setCards([]);
                }
            })
            .catch(error => console.log('error', error));
    }, []);


    const handleShowMoreCards = () => {

        let nextBatch = cards.slice(nextCards, nextCards + extraBatchNumber);
        setNextCards(nextCards + extraBatchNumber);

        displayedEntries = [...cardsToShow, ...nextBatch];
        setCardsToShow(displayedEntries);

        //set the visibility of the Read More button
        if (displayedEntries.length === cards.length) {
            setIsReadMoreVisible(false);
        } else {
            setIsReadMoreVisible(true);
        }

    }

    return (
        <div className="common-questions">
            <h2>Frequently asked questions</h2>
            <span className="subtitle">Want to learn more about how Connatix can take your video strategy to the next-level?
                <Link to="pricing-contact"
                    spy={true}
                    smooth={true}
                    className='help-link'>
                    Reach out!
                </Link></span>
            {
                cardsToShow.map((card, index) => {

                    return (
                        <div key={card.question} className={isCardVisible(index) ? 'common-card visible' : 'common-card hidden'}>
                            <div className="common-card__top" onClick={toggleCardVisibility(index)}>
                                <h3>{card.question}</h3>
                                <span><img src={isCardVisible(index) ? icCaretUp : icCaretDown} alt="caret" /></span>
                            </div>
                            <p>{card.answer}</p>
                        </div>
                    );

                })
            }
            {
                isReadMoreVisible ?
                    <div className="read-more-btn-container">
                        <button type="button" className="read-more-btn" onClick={handleShowMoreCards}>Read More</button>
                    </div> : null
            }


            <img className="decoration" src={commonQuestionsDecoration} alt="decoration" />
        </div>

    );
};

export default CommonQuestions;