import React from 'react';
import PropTypes from 'prop-types';

import styles from './Header.module.scss';
import Container from '../Container/Container';

const Header = props => {
  const backgroundColorStyles = {
    backgroundColor: props.backgroundColor,
  };

  const backgroundImageStyles = {
    backgroundImage: `url(${props.backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: `${props.backgroundPosition ? props.backgroundPosition : 'center'}`
  };

  const headerMaskStyles = {
    backgroundColor: 'rgba(13,25,45,0.6)',
    bottom: 0,
    left: 0,
    right: 0,
    position: 'absolute',
    top: 0
  };

  return (
    <header className={styles['header']} 
            style={Object.assign(
              '',
              props.backgroundColor ? backgroundColorStyles : '',
              props.backgroundImage ? backgroundImageStyles : '')
            }
    >
      {props.hasMask ?
        <div style={headerMaskStyles}/> : undefined
      }

      <Container>
        {props.children}
      </Container>
    </header>
  );
};

Header.propTypes = {
  backgroundColor: PropTypes.string,
  backgroundImage: PropTypes.string,
  hasMask: PropTypes.bool
};

export default Header;