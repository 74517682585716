import React, { useState, useEffect } from 'react';
import ContactFormSection from "../../components/ContactFormSection/ContactFormSection";
import Head from "../../layout/Head/Head";
import axios from "axios";

import HomepageHeader from "./components/HomepageHeader/HomepageHeader";
import VideoSection from "./components/VideoSection/VideoSection";
import StoriesSection from "./components/StoriesSection/StoriesSection";
import HighlightsSection from "./components/HighlightsSection/HighlightsSection";
import LogosGallery from './components/LogosGallery/LogosGallery';
import formDecoration from '../../assets/contact/contact-form-decoration-black.png';
import Carousel from '../../hoc/Carousel/Carousel';
import * as styles from './index.module.scss';


const HomePage = ({bannerConsent}) => {
  const formID = '6e0234e5-83c1-43c0-94ab-9cf4e3d335fb';
  const [logosGallery, setLogosGallery] = useState([]);
  const [homepageCarousel, setHomepageCarousel] = useState([]);
  const sectionOptions = {
    backgroundColor: "#EFF3F5",
    imageBackgroundMargin: "8% 0% 8% -4%",
    direction: "row-reverse"
  }

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/singletons/get/homepageLogos`)
      .then(response => {
        setLogosGallery(response.data.logosGallery ? response.data.logosGallery : [])
      });

      axios.get(`${process.env.REACT_APP_API_URL}/api/collections/get/HomePageFeaturedItemsCarousel`)
      .then(response => {
        setHomepageCarousel(response.data.entries ? response.data.entries : [])
      })
  }, []);

  return (
    <div className={styles['overflow-hidden-container']}>
      <Head title="Connatix"/>

      <HomepageHeader bannerConsent={bannerConsent} />
      <main>
        <VideoSection/>
        <HighlightsSection/>
        <StoriesSection/>

        {/* Carousel */}
        <Carousel carouselItems={homepageCarousel} sectionOptions={sectionOptions}></Carousel>
       
        <LogosGallery logosGallery={logosGallery} logosTitle="Trusted by over 340 top publishers"/>
        <ContactFormSection
          title="Want to chat? Have questions? Drop us a note!"
          titleDecorationColor='#FFCC00'
          description="Contact us to give you even more insight."
          submitText="Contact us"
          formID={formID}
          formDecoration={formDecoration}          
        />
      </main>
    </div>
  )
};

export default HomePage;