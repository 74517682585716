import React from 'react';

import styles from './Grid.module.scss';

const Grid = (props) => (
  <div className={styles['grid']}>
    {props.children}
  </div>
);

export default Grid;