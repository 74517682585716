import React from 'react'
import PropTypes from 'prop-types';

import * as styles from './Office.module.scss';

const Office = ({city, address, location, location2x}) => (
  <div>
    <p className={styles['office__city']}>{city}</p>
    <img srcSet={`${location} 1x, ${location2x} 2x`} alt="" className={styles['office__location']}/>
    <p className={styles['office__address']}>{address}</p>
  </div>
);

Office.propTypes = {
  city: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  location2x: PropTypes.string.isRequired
};

export default Office;