import React, { useState } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import styles from './App.module.scss';

import ScrollToTop from './components/ScrollToTop/ScrollToTop';

import Navigation from "./layout/Navigation/Navigation";

import HomePage from './pages/home';

import ElementsPage from "./pages/platforms/elements";
import PlayspacePage from "./pages/platforms/playspace";
import AdServerPage from "./pages/platforms/adserver";
import AdvertisersPage from "./pages/platforms/advertisers";
import AutomatedContentPage from "./pages/platforms/automatedContent";

import PressPage from './pages/news-and-views/press';
import PressArticlePage from './pages/news-and-views/press/pages/press-article';
import BlogPage from './pages/news-and-views/blog';
import ArticlePage from './pages/news-and-views/blog/pages/blog-article';

import LeadershipPage from './pages/about-us/leadership';
import CareersPage from './pages/about-us/careers';
import PrivacyPolicyPage from './pages/about-us/privacy-policy';
import Footer from './layout/Footer/Footer';
import LeadershipMemberPage from "./pages/about-us/leadership/pages/leadership-member";
import ContactPage from "./pages/contact";
import BlogArticlePreviewPage
  from "./pages/news-and-views/blog/pages/blog-article/pages/blog-article-preview";
import ManageCookiesPage from "./pages/about-us/privacy-policy/pages/manage-cookies";
import ThankYouPage from "./pages/thank-you";
import NotFoundPage from "./pages/not-found";
import SubProcessorsPage from "./pages/sub-processors";
import CustomCookiesConsent from "./components/CustomCookieConsent/CustomCookiesConsent";
import JobDetails from "./pages/about-us/careers/pages/job-details";
import PricingPage from './pages/pricing';
import ContextualPage from './pages/platforms/advertisers/contextual/ContextualPage';
import CopyrightPolicyPage from './pages/about-us/copyright-policy';
import JobApplicantPrivacyPolicyPage from './pages/about-us/job-applicant-privacy-policy';
import OptOutPage from './pages/optout';


const App = () => {
  const [bannerConsent, setBannerConsent] = useState(false);

  return (
    <BrowserRouter>
      <ScrollToTop>
        <CustomCookiesConsent />
        <Navigation bannerConsent={bannerConsent} setBannerConsent={setBannerConsent} />
        <div className={bannerConsent ? styles['app-container'] : styles['app-container--has-banner']}>
          <Switch>
            <Redirect from="/index.html" exact to="/" />
            { /* Remove trailing slash and redirect */ }
            <Redirect from="/:url*(/+)" to={window.location.pathname.slice(0, -1)} />
            <Route path="/" exact render={(props) => <HomePage {...props} bannerConsent={bannerConsent}/>} />
            <Route
              exact
              path='/video-delivery'
              render={(props) => <ElementsPage {...props} />}
            />
            <Redirect from='/elements' to='/video-delivery' />
            <Redirect from='/automatedcontent' to='/video-creation' />

            <Route
              exact
              path='/video-monetization'
              render={(props) => <PlayspacePage {...props} />}
            />
            <Redirect from='/playspace' to='/video-monetization' />
            <Route
              exact
              path='/video-analytics'
              render={(props) => <AdServerPage {...props} />}
            />
            <Redirect from='/adserver' to='/video-analytics' />
            <Route
              exact
              path='/advertisers'
              render={(props) => <AdvertisersPage {...props} />}
            />
            <Route
              exact
              path='/deep-contextual'
              render={(props) => <ContextualPage {...props} />}
            />
            <Route
              exact
              path='/video-creation'
              render={(props) => <AutomatedContentPage {...props} />}
            />
            <Route
              exact
              path="/press"
              render={(props) => <PressPage {...props} />}
            />
    
            <Route
              exact
              path='/blog'
              render={(props) => <BlogPage {...props} />}
            />
            <Route
              exact
              path="/press/:slug"
              render={(props) => <PressArticlePage {...props} />}
            />
            <Route
              exact
              path="/blog/blog-article-preview"
              render={(props) => <BlogArticlePreviewPage {...props} />}
            />
            <Route
              exact
              path='/blog/:slug'
              render={(props) => <ArticlePage {...props} />}
            />
            <Route
              exact
              path="/leadership"
              render={(props) => <LeadershipPage {...props} />}
            />
            <Route
              exact
              path="/leadership/:slug"
              render={(props) => <LeadershipMemberPage {...props} />}
            />
            <Route
              exact
              path="/careers"
              render={(props) => <CareersPage {...props} />}
            />
            <Route
              exact
              path="/careers/gh_jid=:id"
              render={(props) => <JobDetails {...props} />}
            />
            <Route
              exact
              path="/privacy-policy"
              render={(props) => <PrivacyPolicyPage {...props} />}
            />
            <Route
              exact
              path="/privacy-policy/manage-cookies"
              render={(props) => <ManageCookiesPage {...props} />}
            />
            <Route
              exact
              path="/job-applicant-privacy-policy"
              render={(props) => <JobApplicantPrivacyPolicyPage {...props} />}
            />
            <Route
              exact
              path="/optout"
              render={(props) => <OptOutPage {...props} />}
            />
            <Route
              exact
              path="/copyright"
              render={(props) => <CopyrightPolicyPage {...props} />}
            />
            <Route
              exact
              path="/contact"
              render={(props) => <ContactPage {...props} />}
            />
            <Route
              exact
              path="/pricing"
              render={(props) => <PricingPage {...props} />}
            />
            <Route
              exact
              path="/thank-you"
              render={(props) => <ThankYouPage {...props} />}
            />
            <Route
              exact
              path="/sub-processors"
              render={(props) => <SubProcessorsPage {...props} />}
            />
            <Route component={NotFoundPage} />
          </Switch>
        </div>
        <Footer />
      </ScrollToTop>
    </BrowserRouter>
  );
};

export default App;
