import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import styles from './Breadcrumbs.module.scss';

const Breadcrumbs = ({ match, currentPositionText, hasBackground = true }) => {
  const breadcrumbItems = match.url.split('/');

  const breadcrumbsBackgroundStyles = {
    backgroundColor: '#FFFFFF',
    borderRadius: '4px',
    padding: '6px 12px'
  };

  // Remove the first element of the array
  breadcrumbItems.shift();

  // Get the last element of the array
  const lastBreadcrumbItem = breadcrumbItems.pop();

  return (
    <div className={styles['breadcrumbs']}
         style={hasBackground ? breadcrumbsBackgroundStyles : undefined}
    >
      <Link to='/' className={styles['breadcrumb']}>Connatix</Link>
      <span className={styles['breadcrumb-separator']}>|</span>
      {breadcrumbItems.map((breadcrumb, index) => (
        <Fragment key={index}>
          <Link to={`/${breadcrumb}`} className={styles['breadcrumb']}>{capitalizeBreadcrumb(breadcrumb)}</Link>
          <span className={styles['breadcrumb-separator']}>|</span>
        </Fragment>
      ))}
      <span className={styles['breadcrumb-last']}>{currentPositionText || capitalizeBreadcrumb(lastBreadcrumbItem)}</span>
    </div>
  );
};

function capitalizeBreadcrumb(breadcrumb) {
  return breadcrumb.charAt(0).toUpperCase() + breadcrumb.slice(1);
}

export default Breadcrumbs;