import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import * as styles from './TwoRowsTextSection.module.scss';
import FlexContainer from '../FlexContainer/FlexContainer';


const TwoRowsTextSection = ({
                                 image,
                                 imageMobile,
                                 imageMargin,
                                 imageBackgroundMargin,
                                 backgroundColor,
                                 backgroundImage,
                                 decorativeImage,
                                 decorativeImageId,
                                 decorativeImageStyle,
                                 title,
                                 titleDecorationTop,
                                 titleDecorationBottom,
                                 descriptionDecorationColor,
                                 description,
                                 descriptionColor,
                                 buttonText,
                                 buttonLink,
                                 hasPaddingBottom,
                                 info,
                                 infoColor,
                                 direction,
                                 ...props
                               }) => {

  const sectionBackgroundStyles = {
    backgroundColor: backgroundColor ? backgroundColor : '',
    backgroundImage: backgroundImage ? `url(${backgroundImage})` : '',
    backgroundRepeat: backgroundImage ? 'no-repeat' : '',
    backgroundSize: backgroundImage ? 'cover' : ''
  };

  return (
    <div style={sectionBackgroundStyles} 
        className={`${styles['two-row-text-section']} 
        ${hasPaddingBottom ? styles['two-row-text-section--padding-bottom'] : undefined}`}>
      <FlexContainer backgroundColor={backgroundColor} direction={`${direction}`}>
        <div className={styles['section__text']}>        
          { title ? <p className={styles['section__title']}>{title}</p> : undefined}
        
          <div className={styles['section__description-container']}>
            { descriptionDecorationColor ?
              <div className={styles['section__description-decoration']}
                  style={{backgroundColor: descriptionDecorationColor}}/>
              : undefined
            }
            <h4 className={styles['section__description']}
                style={{color: descriptionColor ? descriptionColor : undefined}}
            >{description}</h4>
          </div>
          <p className={styles['section__info']}
            style={{color: infoColor ? infoColor : undefined}}>
            {info}
          </p>
          { buttonText && buttonLink ?
              <Link to={`${buttonLink}`} className={styles['button']}>{buttonText}</Link>             
              : undefined
          }          
        </div>
        
        <div className={styles['section__content']}>
          {props.children}
        </div>

        <div className={styles['section__img']} style={{margin: imageMargin}}>
          <img src={image} className={styles[ imageMobile ? 'image__desktop' : '']}  style={{margin: imageBackgroundMargin}} alt=""/>
          { imageMobile ? <img src={imageMobile} className={styles['image__mobile']} style={{margin: imageBackgroundMargin}} alt=""/>
          : undefined }
        </div>

        <img id={decorativeImageId} className={styles['decorative__image']} src={decorativeImage} alt=""/>          
        <style>{decorativeImageStyle}</style>
      </FlexContainer>
    </div>
  )
};

TwoRowsTextSection.propTypes = {
  image: PropTypes.string,
  imageMobile: PropTypes.string,
  imageMargin: PropTypes.string,
  imageBackgroundMargin: PropTypes.string,
  backgroundImage: PropTypes.string,
  decorativeImage: PropTypes.string,
  decorativeImageId: PropTypes.string,
  decorativeImageStyle: PropTypes.string,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
  hasPaddingBottom: PropTypes.bool,
  direction: PropTypes.string,
  backgroundColor: PropTypes.string,
  title: PropTypes.string,
  descriptionDecorationColor: PropTypes.string,
  description: PropTypes.string,
  descriptionColor: PropTypes.string,
  info: PropTypes.string,
  infoColor: PropTypes.string,
};

export default TwoRowsTextSection;