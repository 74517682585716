import React, { Component } from 'react';
import { Link } from "react-scroll/modules";
import "../../../styles/Header.scss";

import headerBkg from "../../../../../assets/advertisers/advertisers_header_background.png";
import Header from "../../../../../hoc/Header/Header";

class AdvertisersHeader extends Component {

  componentDidMount() {
      // Adding our Video Player Unit
      const script = document.createElement("script");
      script.id = "550ab7e3937c4e5dbe9e18218cf9a071";
      script.innerHTML = (`cnx.cmd.push(function() { cnx({ playerId: "d9d6205a-1cac-4b98-a9a6-d9dabd69c954"}).render("550ab7e3937c4e5dbe9e18218cf9a071");});`);
      const playerContainer = document.getElementsByClassName('header-content__player')[0];
      playerContainer.appendChild(script);
  }

  render() {
    return (
      <Header backgroundImage={headerBkg}>
        <div className='header-content advertisers'>
        <div className='header-content__info-container'>
            <div className='header-content__info'>
              <h2 className='header-content__title'>Advertisers</h2>
              <h1 className='header-content__description'>Drive efficient and effective video ad experiences to captivated audiences.</h1>
              <Link to="advertisers-contact"
                    spy={true}
                    smooth={true}
                    className='header-content__btn'>
                Get in touch
              </Link>
            </div>
          </div>
          <div className='header-content__player-container'>
            <div className='header-content__player-background'>
              <div className='header-content__player'>
              </div>
            </div>
          </div>
        </div>
      </Header>
    )
  }
}

export default AdvertisersHeader;