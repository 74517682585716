import React, {useState, useEffect} from 'react';
import axios from "axios";
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";

import './BrandsCarousel.scss';

const BrandsCarousel = () => {

  const [brands, setBrands] = useState([]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/singletons/get/brandsCarousel`)
      .then(response => {
        setBrands(response.data.brandsCarousel ? response.data.brandsCarousel : [])
      });
  }, []);

  const responsive = {
    0: { items: 2 },
    768: { items: 4 },
    1200: {items: 5}
  };

  const logos = brands.map(logo => (
    <img src={`${process.env.REACT_APP_API_URL}${logo.path}`} alt=""/>
  ));

  return (
    <section className="brands-section">
      <h3 className="brands-section__title">Brands & Partners</h3>
      <AliceCarousel
        autoPlay={true}
        autoPlayInterval={3000}
        disableButtonsControls
        responsive={responsive}
        items={logos}/>
    </section>
  );
};

export default BrandsCarousel;