import React from 'react';
import PropTypes from 'prop-types';

import styles from './MainArticle.module.scss';

import FormatDate from "../FormatDate/FormatDate";
import TextClamp from "../TextClamp/TextClamp";
import { Link } from "react-router-dom";

import dateDecoration from '../../assets/wave-line-horizontal.svg';
import leftDecoration from '../../assets/main-article-left-decoration.png';
import bottomDecoration from '../../assets/main-article-bottom-decoration.png';
import rightDecoration from '../../assets/main-article-right-decoration.png';

import cardOverlay from '../../assets/cards-gradient.png';

const MainArticle = props => (
  <div className={styles['main-article']}>
    <Link to={`${props.url}/${props.mainArticle['title_slug']}`} className={styles['main-article-mobile']}>
      <div className={styles['main-article__cover-container']}>
        <div className={styles['main-article__cover']}
             style={{backgroundImage: `url(${process.env.REACT_APP_API_URL}${props.mainArticle.cover && props.mainArticle.cover.path})`}}/>
      </div>
      <div className={styles['main-article__content']}>
        <div className={styles['main-article__additional-info']}>
          <FormatDate format="MMM Do YYYY" date={props.mainArticle.date}
                      className={styles['main-article__date']}/>
        </div>
        <TextClamp
          text={props.mainArticle.title}
          lines={3}
          ellipsis="..."
          className={styles['main-article__title']}
        />
        <p className={styles['main-article__description']}>
          {props.mainArticle.description}
        </p>
      </div>
      <img src={cardOverlay} alt="" className={styles["main-article--overlay"]}/>
    </Link>

    <div className={styles['main-article-md']}>
      <Link to={`${props.url}/${props.mainArticle['title_slug']}`} className={styles['main-article__cover-container']}>
        {props.hasDecorations ?
          <img src={leftDecoration} alt="" className={styles['cover__left-decoration']}/> : undefined}
        <div className={styles['main-article__cover']}
             style={{backgroundImage: `url(${process.env.REACT_APP_API_URL}${props.mainArticle.cover && props.mainArticle.cover.path})`}}/>
        {props.hasDecorations ?
          <img src={bottomDecoration} alt="" className={styles['cover__bottom-decoration']}/> : undefined}
        {props.hasDecorations ?
          <img src={rightDecoration} alt="" className={styles['cover__right-decoration']}/> : undefined}
      </Link>
      <div className={styles['main-article__content']}>
        <div className={styles['main-article__additional-info']}>
          <div className={styles['additional-info__date-container']}>
            <FormatDate format="MMM Do YYYY" date={props.mainArticle.date}
                        className={styles['main-article__date']}/>
            <img src={dateDecoration} alt="" className={styles['additional-info__date-decoration']}/>
          </div>
        </div>
        <Link to={`${props.url}/${props.mainArticle['title_slug']}`} className={styles['main-article__title']}>
          <TextClamp
            text={props.mainArticle.title}
            lines={3}
            ellipsis="..."
          />
        </Link>
        <p className={styles['main-article__description']}>
          {props.mainArticle.description}
        </p>
        <Link to={`${props.url}/${props.mainArticle['title_slug']}`} className={styles["main-article__button"]}>
          {props.buttonText}
        </Link>
      </div>
    </div>
  </div>
);

MainArticle.propTypes = {
  mainArticle: PropTypes.object.isRequired,
  url: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  hasDecorations: PropTypes.bool
};

export default MainArticle;