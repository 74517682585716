import React from 'react';
import './Tooltip.scss';


const Tooltip = ({text, isTS}) => {
    return (
        <div className={isTS ? 'tooltip' : 'tooltip ad-supported'}>
            {text}
        </div>
    );
}

export default Tooltip;
