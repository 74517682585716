import React, { Component } from 'react';
import { Tabs, Tab } from 'react-tab-view';
import './TechSnapshot.scss';
import TwoRowsTextSection from "../../../../../hoc/TwoRowsTextSection/TwoRowsTextSection";

import responsiveDesignIcon from '../../../../../assets/elements/icons/player-control/responsive-design-icon.svg';
import hlsAdaptiveBitrateIcon from '../../../../../assets/elements/icons/player-control/hls-adaptive-bitrate-icon.svg';
import floatingStickyIcon from '../../../../../assets/elements/icons/player-control/floating-sticky-icon.svg';
import playbackModeIcon from '../../../../../assets/elements/icons/player-control/playback-mode-icon.svg';
import playbackRateIcon from '../../../../../assets/elements/icons/player-control/playback-rate-icon.svg';
import monetizationControlIcon from '../../../../../assets/elements/icons/player-control/monetization-control-icon.svg';

import formatsLayoutsIcon from '../../../../../assets/elements/icons/look-feel/formats-layout-icon.svg';
import colorThemeIcon from '../../../../../assets/elements/icons/look-feel/color-theme-icon.svg';
import customBrandingIcon from '../../../../../assets/elements/icons/look-feel/custom-branding-icon.svg';
import muteWatermarkIcon from '../../../../../assets/elements/icons/look-feel/mute-watermark-icon.svg';
import fixedResponsiveUnitIcon from '../../../../../assets/elements/icons/look-feel/fixed-on-responsive-unit-icon.svg';
import cssSkinningIcon from '../../../../../assets/elements/icons/look-feel/css-skinning-icon.svg';

import connatixExghangeDemandIcon from '../../../../../assets/elements/icons/ad-scheduling-serving/connatix-exchange-demang-icon.svg';
import adQualityControlIcon from '../../../../../assets/elements/icons/ad-scheduling-serving/ad-quality-control-icon.svg';
import adDemandPrioritizationIcon from '../../../../../assets/elements/icons/ad-scheduling-serving/ad-demand-prioritization-icon.svg';
import adFrequencyControlIcon from '../../../../../assets/elements/icons/ad-scheduling-serving/ad-frequency-control-icon.svg';
import vastVpaidIcon from '../../../../../assets/elements/icons/ad-scheduling-serving/vast-vpaid-support-icon.svg';
import headerBiddingIcon from '../../../../../assets/elements/icons/ad-scheduling-serving/header-bidding-icon.svg';

import ampSupportIcon from '../../../../../assets/elements/icons/distribution/amp-support-icon.svg';
import crossDevicesIcon from '../../../../../assets/elements/icons/distribution/cross-devices-icon.svg';
import allOperatingSystemsIcon from '../../../../../assets/elements/icons/distribution/all-operating-systems-icon.svg';
import inAppIcon from '../../../../../assets/elements/icons/distribution/in-app-icon.svg';

import videoSchedulingIcon from '../../../../../assets/elements/icons/media/video-scheduling-icon.svg';
import closedCaptioningIcon from '../../../../../assets/elements/icons/media/closed-captioning-support-icon.svg';
import dynamicPlaylistsIcon from '../../../../../assets/elements/icons/media/dinamic-playlists-icon.svg';
import liveStreamingIcon from '../../../../../assets/elements/icons/media/live-streaming-icon.svg';
import xmlIcon from '../../../../../assets/elements/icons/media/xml-icon.svg';
import feedAuthenticationIcon from '../../../../../assets/elements/icons/media/feed-authentication-icon.svg';

import jsApiIcon from '../../../../../assets/elements/icons/other/js-api-icon.svg';
import automaticMigrationIcon from '../../../../../assets/elements/icons/other/automatic-migration-icon.svg';
import liveDashboardIcon from '../../../../../assets/elements/icons/other/live-dashboard-icon.svg';
import contentAdBasedIcon from '../../../../../assets/elements/icons/other/content-ad-based-icon.svg';
import devSupportIcon from '../../../../../assets/elements/icons/other/dev-support-icon.svg';
import smallerScriptIcon from '../../../../../assets/elements/icons/other/smaller-script-icon.svg';

import decorativeImage from '../../../../../assets/elements/elements_decorative_section5.png';
const decorativeImageId = 'techSnapshot__decorative';
const decorativeImageStyle = `
  #${decorativeImageId} {
    max-width: 120px;
    min-width: 75px;
    width: 10%;
    bottom: -75px;
    left: 1%;
  }

  @media only screen and (min-width: 48rem) {
    #${decorativeImageId} {
      width: 10%;
      bottom: -95px;
    }
  }

  @media only screen and (min-width: 62rem) {
    #${decorativeImageId} {
      width: 30%;
      bottom: -25%;
    }
  }

  @media only screen and (min-width: 75rem) {
    #${decorativeImageId} {
      left: -10%;
    }
  }
`;

export class TechSnapshot extends Component {
  constructor(props) {
    super(props);

    this.state = {
      headerLinks: [],
      techSnapshotIndex: 0
    }
  }

  componentDidMount() {
    const tabView = document.getElementById('tech-snapshot-section').getElementsByClassName('iMnhxj');
    const tabViewHeader = tabView[0].getElementsByTagName('div')[0];
    const tabViewLinks = [...tabViewHeader.getElementsByTagName('a')];

    this.setState({
      headerLinks: tabViewLinks
    });

    this.interval = setInterval(this.onNavigateCarousel, 5000);

    // Update index from state with the index of the clicked element
    for(let i = 0; i < tabViewLinks.length; i++) {
      tabViewLinks[i].addEventListener('click', () => {
        clearInterval(this.interval);

        this.setState({
          techSnapshotIndex: tabViewLinks.indexOf(tabViewLinks[i])
        });
        this.interval = setInterval(this.onNavigateCarousel, 5000);
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  onNavigateCarousel = () => {
    if (this.state.techSnapshotIndex >= this.state.headerLinks.length - 1) {
      this.setState({
        techSnapshotIndex: 0
      });
    } else {
      this.setState({
        techSnapshotIndex: this.state.techSnapshotIndex + 1
      })
    }
    this.state.headerLinks[this.state.techSnapshotIndex].click();
  };

  render() {
    return(
      <div id="tech-snapshot-section">
        <TwoRowsTextSection
          backgroundColor="#EFF3F5"
          decorativeImage={decorativeImage}
          decorativeImageId={decorativeImageId}
          decorativeImageStyle={decorativeImageStyle}
          descriptionDecorationColor="#F2DC4F"
          hasPaddingBottom={true}
          description="Tech snapshot"
          descriptionColor="#000000"
        >
          <Tabs headers={techSnapshotHeaders[0]}>
            {techSnapshotData.map((item, index) => (
              <Tab key={index}>
                <div className='tech-snapshot-tab'>
                  {item.elements.map((element, index) => (
                    <div key={index} className={`tech-snapshot-element ${item.title === 'Distribution' ? 'tech-snapshot-element--big' : ''}`}>
                      <img src={element.icon} alt=""/>
                      <span className='tech-snapshot-element__text'>{element.text}</span>
                    </div>
                  ))}
                </div>
              </Tab>
            ))}
          </Tabs>
        </TwoRowsTextSection>
      </div>
    );
  }
}

const techSnapshotData = [
  {
    title: 'Player Controls',
    elements: [
      {
        icon: responsiveDesignIcon,
        text: 'Responsive Design'
      },
      {
        icon: hlsAdaptiveBitrateIcon,
        text: 'HLS Adaptive Bitrate',
      },
      {
        icon: floatingStickyIcon,
        text: 'Floating & Sticky Behaviors'
      },
      {
        icon: playbackModeIcon,
        text: 'Playback Mode'
      },
      {
        icon: playbackRateIcon,
        text: 'Playback Rate'
      },
      {
        icon: monetizationControlIcon,
        text: 'Monetization Control'
      }
    ]
  },
  {
    title: 'Look & Feel',
    elements: [
      {
        icon: formatsLayoutsIcon,
        text: 'Formats & Layouts'
      },
      {
        icon: colorThemeIcon,
        text: 'Color Theme',
      },
      {
        icon: customBrandingIcon,
        text: 'Custom Branding'
      },
      {
        icon: muteWatermarkIcon,
        text: 'Mute Watermark'
      },
      {
        icon: fixedResponsiveUnitIcon,
        text: 'Fixed or Responsive Unit'
      },
      {
        icon: cssSkinningIcon,
        text: 'CSS Skinning'
      }
    ]
  },
  {
    title: 'Ad Scheduling & Serving',
    elements: [
      {
        icon: connatixExghangeDemandIcon,
        text: 'Connatix Exchange Demand'
      },
      {
        icon: adQualityControlIcon,
        text: 'Ad Quality Control',
      },
      {
        icon: adDemandPrioritizationIcon,
        text: 'Ad Demand Prioritization'
      },
      {
        icon: adFrequencyControlIcon,
        text: 'Ad Frequency Control'
      },
      {
        icon: vastVpaidIcon,
        text: 'VAST / VPAID Support'
      },
      {
        icon: headerBiddingIcon,
        text: 'Smart Server Bidding'
      }
    ]
  },
  {
    title: 'Distribution',
    elements: [
      {
        icon: crossDevicesIcon,
        text: 'Web, Cross Device',
      },
      {
        icon: ampSupportIcon,
        text: 'AMP'
      },
      {
        icon: allOperatingSystemsIcon,
        text: 'All Operating Systems'
      },
      {
        icon: inAppIcon,
        text: 'In-App'
      }
    ]
  },
  {
    title: 'Media',
    elements: [
      {
        icon: videoSchedulingIcon,
        text: 'Video Scheduling'
      },
      {
        icon: closedCaptioningIcon,
        text: 'Closed Captioning Support',
      },
      {
        icon: dynamicPlaylistsIcon,
        text: 'Dynamic Playlists'
      },
      {
        icon: liveStreamingIcon,
        text: 'Live Streaming'
      },
      {
        icon: xmlIcon,
        text: 'XML & Non-Standard Feed Ingestion'
      },
      {
        icon: feedAuthenticationIcon,
        text: 'Feed Authentication'
      }
    ]
  },
  {
    title: 'Other',
    elements: [
      {
        icon: jsApiIcon,
        text: 'JavaScript API'
      },
      {
        icon: automaticMigrationIcon,
        text: 'Automatic Migration',
      },
      {
        icon: liveDashboardIcon,
        text: 'Live Dashboard'
      },
      {
        icon: contentAdBasedIcon,
        text: 'Content & Ad Based Reporting'
      },
      {
        icon: devSupportIcon,
        text: 'Developer Support Portal'
      },
      {
        icon: smallerScriptIcon,
        text: '3x Smaller Script Size'
      }
    ]
  }
];

const techSnapshotHeaders = new Array(techSnapshotData.map(item => item.title));