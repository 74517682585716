import React, { Fragment } from 'react';
import FormatDate from "../../../../../../../components/FormatDate/FormatDate";
import styles from "./ArticleHeader.module.scss";

const ArticleHeader = (props) => (
  <Fragment>
    <h1 className={styles['article-title']}>{props['articleTitle']}</h1>

    <FormatDate format="MMM Do YYYY" date={props['articleDate']} className={styles['article-date']}/>

    <img src={`${process.env.REACT_APP_API_URL}/${props['articleCover']}`}
         alt=""
         className={styles['article-cover']}
    />
    <figure className={styles['author-avatar-container']}>
      <img src={`${process.env.REACT_APP_API_URL}/${props['authorAvatar']}`}
           alt=""
           className={styles['author-avatar']}
      />
    </figure>
    <span className={styles['author-name']}>{props['authorName']}</span>
    <span className={styles['author-position']}>{props['authorPosition']}</span>
    <h2 className={styles['article-description']}>{props['articleDescription']}</h2>
  </Fragment>
);

export default ArticleHeader;