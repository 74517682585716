import React, { Component } from 'react';
import { Link } from "react-scroll/modules";
import "../../../styles/Header.scss";
import headerBkg from "../../../../../assets/common-header-bg.png";
// import headerLogo from "../../../../../assets/elements/elements_logo.png";
import Header from "../../../../../hoc/Header/Header";

class ElementsHeader extends Component {

  componentDidMount() {
    // Adding our Video Player Unit
    const script = document.createElement("script");
    script.innerText = (`cnx.cmd.push(function(){cnx({playerId: 'a5173d6b-4fd1-4cd6-b731-ef156b463ff5', mediaId: '6ed5028e-6d52-4eca-aa03-30c7b16d5449'}).render('83d64615516e4c5bb2366d90ddb7e374');});`);
    script.id = "83d64615516e4c5bb2366d90ddb7e374";
    const playerContainer = document.getElementsByClassName('header-content__player')[0];
    playerContainer.appendChild(script);
  }

  render() {
    return (
      <Header backgroundImage={headerBkg}>
        <div className='header-content video-delivery'>
          <div className='header-content__info-container'>
            <div className='header-content__info'>
              {/* <img className='header-content__logo' src={headerLogo} alt=""/> */}
              <div className='header-tag'>
                Video Delivery
              </div>
              <h1 className='header-content__title'> Leave a lasting impression through flawless delivery</h1>
              <h2 className='header-content__description'>Put your video center stage with a fully customizable player and intuitive video library</h2>
            
              <Link to={'elements-contact'}
                spy={true}
                smooth={true}
                className='header-content__btn'>
                Get in touch
              </Link>
            </div>
          </div>
          <div className='header-content__player-container'>
            <div className='header-content__player-background'>
              <div className='header-content__player'>
              </div>
            </div>
          </div>
        </div>
      </Header>
    )
  }
}

export default ElementsHeader;