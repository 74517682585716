import React, { Fragment, useState, useEffect } from 'react';
import axios from 'axios';

import * as styles from './index.module.scss';

import Loading from "../../../components/Loading/Loading";
import MainArticle from "../../../components/MainArticle/MainArticle";
import Header from "../../../hoc/Header/Header";
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs";
import Head from "../../../layout/Head/Head";
import Container from "../../../hoc/Container/Container";
import Grid from "../../../hoc/Grid/Grid";
import Card from "../../../hoc/Card/Card";
import CoverCard from "../../../components/CoverCard/CoverCard";
import LazyLoad from "react-lazyload";

const PressPage = props => {
  const [mainPressArticle, setMainPressArticle] = useState([]);
  const [pressArticles, setPressArticles] = useState([]);

  // Get Press Articles
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/collections/get/press`)
      .then(response => {
        const articles = response.data.entries ? response.data.entries : [];

        setMainPressArticle(articles.filter(article => article.mainPressArticle));
        setPressArticles(articles.filter(article => !article.mainPressArticle));
      })
      .catch(error => console.log('Get Press Articles: error:: ', error));
  }, []);

  pressArticles.sort((a, b) => (a.date < b.date) ? 1 : -1);

  return (
    <Fragment>
      <Head title="Connatix - Press"/>
      <Fragment>
        <Header>
          <Breadcrumbs {...props} hasBackground={false}/>
          {mainPressArticle.length ?
            <MainArticle
              mainArticle={mainPressArticle[0]}
              url={props.match.url}
              buttonText="Full Article"
              hasDecorations={false}
            />
            : <Loading/>}
        </Header>

        <main className={styles['press-list']}>
          <Container>
            <Grid>
              {pressArticles.map(article => (
                <LazyLoad height={100} key={article._id}>
                  <Card>
                    <CoverCard {...article} url={props.match.url} buttonText="Full Article"/>
                  </Card>
                </LazyLoad>
              ))}
            </Grid>
          </Container>
        </main>
      </Fragment>
    </Fragment>
  )

};

export default PressPage;
