import React from 'react'
import Container from "../../hoc/Container/Container";
import * as styles from './index.module.scss';

import Head from "../../layout/Head/Head";

const NotFoundPage = () => {
  return (
    <div className={styles['not-found-container']}>
      <Head title="Connatix - Not found" />
      <Container>
        <h1 className={styles['not-found__title']}>Page not found!</h1>
      </Container>
    </div>
  )
};

export default NotFoundPage;