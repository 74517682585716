import React, { Fragment, useState, useEffect } from 'react';
import axios from "axios";

import styles from './index.module.scss';

import ContentCMS from "../../../components/ContentCMS/ContentCMS";
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs";
import Container from "../../../hoc/Container/Container";
import Head from "../../../layout/Head/Head";
import Loading from "../../../components/Loading/Loading";

const CopyrightPolicyPage = props => {
  const [copyrightPolicy, setCopyrightPolicy] = useState([]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/singletons/get/copyright`)
      .then(response => {
        setCopyrightPolicy(response.data.content ? response.data.content : []);
      })
      .catch(error => console.log('error', error));
  }, []);

  return (
    <Fragment>
      {copyrightPolicy.length ?
        <Fragment>
          <Head title='Connatix - Copyright' />
          <Container>
            <Breadcrumbs {...props} currentPositionText='Copyright'/>
            <main className={styles['copyright-policy']}>
              <ContentCMS data={copyrightPolicy}/>
            </main>
          </Container>
        </Fragment>
        :
        <Loading/>
      }
    </Fragment>
  );
};

export default CopyrightPolicyPage;
