import React, { useState } from 'react';
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";

import './QuotesCarousel.scss';
import arrowLeft from '../../assets/svg/arrow-left.svg';
import arrowRight from '../../assets/svg/arrow-right.svg';
import blockQuotes from '../../assets/svg/blockquote.svg';

const QuotesCarousel = ({ quotes, quotesSectionTitle }) => {

    const [activeIndex, setActiveIndex] = useState(0);
    const [isNavButtonClicked, setNavButtonClicked] = useState(false);

    const slidePrev = () => {
        setNavButtonClicked(true);
        if (activeIndex > 0) {
            setActiveIndex(activeIndex - 1);
        } else {
            setActiveIndex(quotes.length - 1); //when it reaches the first slide go back to the last one
        }
    };

    const slideNext = () => {
        setNavButtonClicked(true);
        if (activeIndex < quotes.length - 1) {
            setActiveIndex(activeIndex + 1);
        } else {
            setActiveIndex(0); //when it reaches the end of the slides reset it back to the first one (activeIndex = 0)
        }
    };

    //this fires when the slide changes by itself, NOT when we click prev or next
    const onSlideChange = () => {
        setNavButtonClicked(false);
    }

    const syncActiveIndex = ({ item }) => {
        setActiveIndex(item);
    };

    const carouselQuotes = quotes.map((quote, index) => {

        return (

            <div className='quote-container'>

                <div className='logo-container'>
                    {/* Logo/Company Name */}

                    {
                        <a className={quote.companyUrl ? 'logo' : 'logo inactive-link'} href={quote.companyUrl ? quote.companyUrl : ''} target={quote.companyUrl ? '_blank' : ''} rel="noopener noreferrer">


                            {quote.logo.path ?
                                <img src={`${process.env.REACT_APP_API_URL}${quote.logo.path}`} alt="logo" />
                                :
                                !quote.logo.path && quote.companyName ?
                                    <span>{quote.companyName}</span>

                                    : null
                            }
                        </a>

                    }

                    {/* Counter */}

                    {quotes.length > 1 ?
                        <span>{index + 1} / {quotes.length}</span>
                        : null
                    }
                </div>


                {/* Quote Block */}
                <div className='quote-block'>
                    <div className='blockquotes-container'>
                        <img src={blockQuotes} alt="quotes"></img>
                    </div>
                    <p>{quote.quote}</p>


                </div>

                {/* About the Author */}
                <div className='author'>
                    {quote.authorAvatar.path ?
                        <div className='author-avatar' style={{backgroundImage: `url(${process.env.REACT_APP_API_URL}${quote.authorAvatar.path})`}}>
                           
                        </div>
                        : null
                    }

                    <div className='about-author'>
                        {quote.authorName ?
                            <span className='author-name'>&#8212;
                                {quote.authorName}</span>
                            : null
                        }

                        <span className='author-role'>{quote.authorRole}</span>
                    </div>
                </div>

            </div>



        );
    });

    return (
        <div className={isNavButtonClicked ? 'cnx-carousel-wrapper btn-clicked' : 'cnx-carousel-wrapper'}>
            <div className='cnx-carousel-wrapper__top'>

                {quotesSectionTitle || quotes.length > 1 ?
                    <div className='title-container'>
                        <div className='title-decoration'></div>
                        {quotesSectionTitle ?
                            <h4>{quotesSectionTitle}</h4>
                            : null
                        }

                    </div>
                    : null

                }

                <div className='nav-btns-container'>
                    {
                        quotes.length > 1 ?
                            <button className="nav-btn" onClick={slidePrev}>
                                <img src={arrowLeft} alt="prev"></img>
                            </button>
                            : null
                    }
                    {
                        quotes.length > 1 ?
                            <button className="nav-btn" onClick={slideNext}>
                                <img src={arrowRight} alt="next"></img>
                            </button>
                            : null
                    }
                </div>
            </div>


            <AliceCarousel
                activeIndex={activeIndex}
                // dont autoplay unless there are more than 1 quote items
                autoPlay={quotes.length > 1 ? true : false}
                autoPlayStrategy='none'
                autoPlayInterval={10000}
                infinite={true}
                items={carouselQuotes}
                disableDotsControls={true}
                disableButtonsControls
                onSlideChange={onSlideChange}
                onSlideChanged={syncActiveIndex}

            />

        </div>



    );
};


export default QuotesCarousel;