import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import protobuf from 'protobufjs';
import Head from '../../layout/Head/Head';
import Container from '../../hoc/Container/Container';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import styles from './index.module.scss';
import proto from './opt.proto';

const OPTOUT_COOKIE_KEY = 'cnx_optout';

const OptOutPage = props => {
    const [isChecked, setIsChecked] = useState(getCookieValue(OPTOUT_COOKIE_KEY) === 'True');
    const [isTouched, setIsTouched] = useState(false);

    const checkHandler = () => {
        setIsTouched(true);
        setIsChecked(!isChecked);
    };

    const getProtoEncodedData = async () => {
        try {
            const root = await protobuf.load(proto);
            const Request = root.lookupType("contracts.core.opt.v1.OptRequest");
            const data = {optOut: isChecked};
            const errMsg = Request.verify(data);
            if (errMsg) {
                throw Error(errMsg);
            }

            const serialized = Request.encode(data).finish();
            const decoder = new TextDecoder();
            return decoder.decode(serialized);
        } catch (error) {
            console.log('Get Protobuf: error:: ', error)
        }
    }

    const saveSettings = async () => {
        // fetch protobuf request
        axios.post(`${process.env.REACT_APP_OPTOUT_URL}`, await getProtoEncodedData(), {
            withCredentials: true,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            responseType: 'arraybuffer'
        }).then(() => {
            setIsTouched(false);
        });
    };

    function getCookieValue(key) {
        const cookieValue = document.cookie
            .split("; ")
            .find((row) => row.startsWith(`${key}=`));
        if (cookieValue) {
            return cookieValue.split("=")[1];
        }
        return '';
    }

    return (
        <div>
            <Head title='Connatix - Do Not Sell or Share My Personal Information'/>
            <Container>
                <Breadcrumbs {...props} currentPositionText='Do Not Sell or Share My Personal Information'/>
                <div className={styles['optout-wrapper']}>
                    <h1>Do Not Sell or Share My Personal Information</h1>
                    <p>Connatix developed and operates a video advertising solution (the "Solution") which is used on
                        various websites, apps, and other online media ("Online Properties") owned and operated by
                        others. When you access an Online Property that uses the Solution, Connatix collects and
                        processes your personal information, as explained in more detail on our <Link
                            to="/privacy-policy#videoPlatformPrivacy">Video Platform Privacy
                            Notice</Link>. We then share your personal information for online behaviorally targeted ads.
                        However, you have a right to opt-out of that sharing, which means that you have a right to
                        direct us to stop sharing your personal information for targeted ads.</p>

                    <div className={styles['optout-wrapper__preference-section']}>
                        <p className={styles['preference-section__title']}>Manage Preference</p>
                        <div className={styles['switch--primary']}>
                            <div className={styles['switch--primary__input']}>
                                <input
                                    type="checkbox"
                                    className={styles['switch--primary__checkbox']}
                                    checked={isChecked}
                                    onChange={checkHandler}
                                />
                                <div className={styles['switch--primary__knobs']}></div>
                                <div className={styles['switch--primary__layer']}></div>
                            </div>
                            <div className={styles['switch--primary__label']}>Do not sell or share my personal
                                information
                            </div>
                        </div>
                        <div onClick={saveSettings}
                             className={isTouched ? styles['button--primary'] : styles['button--disabled']}>Save
                            Settings
                        </div>
                        <p className={styles['preference-section__note']}>*Your opt-out will take effect immediately
                            (or
                            within &lt;&gt; time, is needed).</p>
                    </div>

                    <div className={styles['optout-wrapper__divider']}></div>
                </div>
            </Container>
        </div>
    );
};

export default OptOutPage;
