import React, { useState, useEffect } from 'react';
import axios from "axios";

import Head from '../../../layout/Head/Head';
import ContactFormSection from "../../../components/ContactFormSection/ContactFormSection";
import TwoColumnsTextSection from "../../../hoc/TwoColumnsTextSection/TwoColumnsTextSection";
import TwoRowsTextSection from "../../../hoc/TwoRowsTextSection/TwoRowsTextSection";
import AdvertisersHeader from './components/AdvertisersHeader/AdvertisersHeader';
import BrandsCarousel from './components/BrandsCarousel/BrandsCarousel';
import styles from './index.module.scss';
import QuotesCarousel from '../../../components/QuotesCarousel/QuotesCarousel';

import section1Image from '../../../assets/advertisers/advertisers_section1.png';
import section2Image from '../../../assets/advertisers/advertisers_section2.png';
import section2ImageMobile from '../../../assets/advertisers/advertisers_section2_mobile.png';
import section3Image from '../../../assets/advertisers/advertisers_section3.png';
import section4Image from '../../../assets/advertisers/advertisers_section4.png';

import section1DecorativeImage from '../../../assets/advertisers/advertisers_decorative_section1.png';
import section2DecorativeImage from '../../../assets/advertisers/advertisers_decorative_section2.png';
import section3DecorativeImage from '../../../assets/advertisers/advertisers_decorative_section3.png';
import formDecoration from '../../../assets/contact/contact-form-decoration-purple.png';

const section1DecorativeImageId = 'section1__decorative';
const section1DecorativeImageStyle = `
  #${section1DecorativeImageId} {
    max-width: 120px;
    min-width: 70px;
    width: 15%;
    bottom: -80px;
    right: 1%;
  }

  @media only screen and (min-width: 48rem) {
    #${section1DecorativeImageId} {
      bottom: -95px;
    }
  }

  @media only screen and (min-width: 62rem) {
    #${section1DecorativeImageId} {
      width: 20%;
      bottom: -30%;
    }
  }

  @media only screen and (min-width: 75rem) {
    #${section1DecorativeImageId} {
      right: -10%;
    }
  }
`;

const section2DecorativeImageId = 'section2__decorative';
const section2DecorativeImageStyle = `
  #${section2DecorativeImageId} {
    max-width: 100px;
    min-width: 50px;
    width: 7%;
    bottom: -85px;
    left: 0;
  }

  @media only screen and (min-width: 48rem) {
    #${section2DecorativeImageId} {
      bottom: -110px;
      width: 8%;
    }
  }

  @media only screen and (min-width: 62rem) {
    #${section2DecorativeImageId} {
      width: 30%;
      bottom: -25%;
    }
  }

  @media only screen and (min-width: 75rem) {
    #${section2DecorativeImageId} {
      left: calc((100vw - 64rem) / -2);
    }
  }
`;

const section3PlayerScript = `cnx.cmd.push(function() { cnx({ playerId: "b2ee0727-47f7-40b1-bd20-322aa7d1ac59" }).render("dc90db734bba4b8e97279cfe7d194362"); });`;
const section3PlayerScriptId = 'dc90db734bba4b8e97279cfe7d194362';
const section3PlayerStyles = `
  .player {
    position: absolute;
    top: 12%;
    right: -4%;
    width: 108%;
  }

  .image {
    display: block;
  }

  @media only screen and (max-width: 399px) {
    .player {
      position: static;
      margin: 8% -8%;
      width: auto;
    }

    .image {
      display: none;
    }
  }
`;

const AdvertisersPage = props => {
  const formID = '6e0234e5-83c1-43c0-94ab-9cf4e3d335fb';
  const [quotes, setQuotes] = useState([]);
  const [quotesSectionTitle, setQuotesSectionTitle] = useState();
  const [quotesSectionBgColor, setQuotesSectionBgColor] = useState();

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/collections/get/advertisersQuotesCarousel`)
      .then(response => {
        setQuotes(response.data.entries ? response.data.entries : [])
        if (response.data.entries) {
          //Set Quote Section Background Color
          let entryWBgColor = response.data.entries.find(entry => entry.backgroundColor);
          setQuotesSectionBgColor(entryWBgColor.backgroundColor);
          //Set Quote Section Title
          let entryWTitle = response.data.entries.find(entry => entry.sectionTitle);
          setQuotesSectionTitle(entryWTitle.sectionTitle);
        }

      })
      .catch(error => console.log('Quotes Carousel Error: ', error));

  }, []);
  return (
    <div className={styles['overflow-hidden-container']}>
      <Head title="Connatix - Advertisers" />
      <AdvertisersHeader {...props} />

      <main className={styles['advertisers-page']}>
        {/* Section 1 */}
        <section>
          <TwoColumnsTextSection
            image={section1Image}
            imageMargin="4% 0 6% 0"
            imageBackgroundMargin="-8% 6%"
            imageWidth="88%"
            direction="row-reverse"
            backgroundColor="#EFF3F5"
            decorativeImage={section1DecorativeImage}
            decorativeImageId={section1DecorativeImageId}
            decorativeImageStyle={section1DecorativeImageStyle}
            hasPaddingBottom={true}
            descriptionDecorationColor="#FFCC00"
            title="TECH THAT CONNECTS"
            description="Take direct charge of ad performance"
            info="Deliver on exclusive inventory from top publishers. 
                  Amplify campaign performance through an end-to-end solution that offers better transparency and pricing."
          />
        </section>

        {/* Section 2 */}
        <section>
          <TwoRowsTextSection
            image={section2Image}
            imageMobile={section2ImageMobile}
            imageBackgroundMargin="4% -4rem 0 0"
            direction="column"
            backgroundColor="#FFFFFF"
            descriptionDecorationColor="#FFCC00"
            decorativeImage={section2DecorativeImage}
            decorativeImageId={section2DecorativeImageId}
            decorativeImageStyle={section2DecorativeImageStyle}
            hasPaddingBottom={true}
            description="Choose the most direct path to the page"
            info="Increase efficiency by optimizing your working media and reducing supply chain costs. 
                  With fewer hops to pass through, see your ad budgets go further for each meaningful impression."
          />
        </section>

        {/* Section 3 */}
        <section>
          <TwoColumnsTextSection
            playerScript={section3PlayerScript}
            playerScriptId={section3PlayerScriptId}
            playerStyles={section3PlayerStyles}
            image={section3Image}
            imageBackgroundMargin="2% -10%"
            imageWidth="120%"
            direction="row-reverse"
            backgroundColor="#EFF3F5"
            decorativeImage={section3DecorativeImage}
            decorativeImageId={section1DecorativeImageId}
            decorativeImageStyle={section1DecorativeImageStyle}
            hasPaddingBottom={true}
            descriptionDecorationColor="#FFCC00"
            description="Captivate an engaged audience"
            info="Deliver at scale against premium video inventory when readers are immersed in hand-selected, 
                  editorial content or story-like formats."
          />
        </section>

        {/* Section 4 */}
        <section>
          <TwoColumnsTextSection
            image={section4Image}
            imageBackgroundMargin="4% 0 4% -4%"
            imageWidth="110%"
            direction="row"
            backgroundColor="#FFFFFF"
            descriptionDecorationColor="#FFCC00"
            hasPaddingBottom={true}
            description="Kick targeting up a notch using in-video context"
            info="Leverage video categories and sentiment to ensure relevance, 
                  brand safety, positive associations, and zero reliance on third-party cookies."
            buttonText="LEARN MORE"
            buttonLink="/deep-contextual"

          />
        </section>

        {/* Section 5 - Brand & Partenrs */}
        <BrandsCarousel />

        {/*Quotes */}
        <section className={`quotes-section ${quotesSectionBgColor === 'white' ? 'bg-white' : 'bg-gray'}`}>
          <QuotesCarousel quotes={quotes} quotesSectionTitle={quotesSectionTitle} />
        </section>

        {/* Contact form */}
        <ContactFormSection id="advertisers-contact"
          title="Ready to level up your video advertising?"
          titleDecorationColor='#F2DC4F'
          description="Reach out to get started."
          formID={formID}
          formDecoration={formDecoration}
        />
      </main>
    </div>
  )
};

export default AdvertisersPage;