import React, { Component } from 'react';
import ReactHtmlParser from "react-html-parser";
import InnerHTML from 'dangerously-set-inner-html';
import './ContentCMS.scss';

export default class ContentCMS extends Component {
  constructor(props) {
    super(props);

    this.buildArticleContent.bind(this)
  }

  buildArticleContent = (content) => {
    if (content) {
      return content.map((item, index) => {
        switch (item.component) {
          case ('text'):
            return ReactHtmlParser(item.settings.text);
          case ('html'):
            return <InnerHTML key={item.settings.html} html={item.settings.html}/>
          case('divider'):
            return (
              <div className="divider" key={index}>
                ...
              </div>);
          case ('heading'):
            return React.createElement(`${item.settings.tag || 'h1'}`, {key: index, id: `${item.settings.id}`}, `${item.settings.text}`);
          case ('image'):
            return item.settings.image ?
              <img key={index} src={`${process.env.REACT_APP_API_URL}${item.settings.image['path']}`}
                   style={{width: `${item.settings.width}%`, height: `${item.settings.height}px`}}
                   alt=""/> : undefined;
          case ('section'):
            return (
              <div className="content-cms-section" key={index}>
                {this.buildArticleContent(item.children)}
              </div>
            );
          case ('grid'):
            return (
              <div className="content-cms-grid" key={index}
                   style={{gridTemplateColumns: `repeat(${item.columns.length}, 1fr)`}}>
                {item.columns.map((column, index) => (
                  <div className="content-cms-grid-column" key={index}>
                    {this.buildArticleContent(column.children)}
                  </div>
                ))}
              </div>
            );
          default:
            return undefined;
        }
      });
    }
  };

  componentDidMount() {
    // Adding the quote to the blog quote element
    const element = document.createElement('i');
    element.setAttribute('class', 'material-icons');
    element.innerText = 'format_quote';
    const quotes = document.getElementsByTagName('blockquote')[0];
    if (quotes) {
      quotes.insertBefore(element, quotes.childNodes[0]);
    }
  }

  render() {
    return (
      <div id="content-cms">
        {this.buildArticleContent(this.props.data)}
      </div>
    )
  }
}