import React from 'react';
import styles from './FlexContainer.module.scss'

// Used to center the content
// By default the max width is 64rem.
const FlexContainer = props => {
  return (
    <div className={`${styles['flex-container']} ${styles[props.direction]}`}
    style={{backgroundColor: props.backgroundColor }} >
      {props.children}
    </div>
  )
};

export default FlexContainer;
