import React, { Component } from 'react';
import { Link } from "react-scroll/modules";
import "../../../styles/Header.scss";

import headerBkg from "../../../../../assets/common-header-bg.png";
import Header from "../../../../../hoc/Header/Header";

class AutomatedContentHeader extends Component {

  componentDidMount() {
    // Adding our Video Player Unit
    const script = document.createElement("script");
    script.id = "c832373e0d4b4a02be2043425333a674";
    script.innerHTML = (`cnx.cmd.push(function() { cnx({ playerId: "9094672c-1628-487c-ae9f-a8d2df49dc4c"}).render("c832373e0d4b4a02be2043425333a674");});`);
    const playerContainer = document.getElementsByClassName('header-content__player')[0];
    playerContainer.appendChild(script);
  }

  render() {
    return (
      <Header backgroundImage={headerBkg}>
        <div className='header-content video-creation'>
          <div className='header-content__info-container'>
            <div className='header-content__info'>
              <div className='header-tag'>
                Video Creation
              </div>
              <h1 className='header-content__title'>Effortlessly produce on-brand video readers will love</h1>
              <h2 className='header-content__description'>Grow video libraries with a diverse set of content tools</h2>
              <Link to="automated-content-contact"
                spy={true}
                smooth={true}
                className='header-content__btn'>
                Get in touch
              </Link>
            </div>
          </div>
          <div className='header-content__player-container'>
            <div className='header-content__player-background'>
              <div className='header-content__player'>
              </div>
            </div>
          </div>
        </div>
      </Header>
    )
  }
}

export default AutomatedContentHeader;