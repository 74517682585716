import React from 'react';
import PropTypes from 'prop-types';
import Container from "../../../../../hoc/Container/Container";
import Grid from "../../../../../hoc/Grid/Grid";
import Card from "../../../../../hoc/Card/Card";
import CoverCard from "../../../../../components/CoverCard/CoverCard";

const CareerBlogList = props => {
  return (
    <div style={{marginTop: '-150px'}}>
      <Container>
        <Grid>
          {props.careerBlogList.map(careerBlogPost =>(
            <Card key={careerBlogPost._id}>
              <CoverCard {...careerBlogPost} url={props.redirectUrl} buttonText="Read More" hasPadding={true}/>
            </Card>
          ))}
        </Grid>
      </Container>
    </div>
  );
};

CareerBlogList.propTypes = {
  careerBlogList: PropTypes.arrayOf(PropTypes.object).isRequired,
  redirectUrl: PropTypes.string.isRequired
};

export default CareerBlogList;