import React, { Fragment, useState, useEffect } from 'react';
import axios from "axios";

import styles from './index.module.scss';
import Head from "../../../../../layout/Head/Head";
import Breadcrumbs from "../../../../../components/Breadcrumbs/Breadcrumbs";
import Loading from '../../../../../components/Loading/Loading';
import Container from "../../../../../hoc/Container/Container";

import linkedIn from "../../../../../assets/linkedIn-icon.svg";
import twitter from "../../../../../assets/twitter-icon.svg";

import ContentCMS from "../../../../../components/ContentCMS/ContentCMS";

const LeadershipMemberPage = props => {
  const [leadershipMember, setLeadershipMember] = useState({});

  useEffect(() => {
    const slug = props.match.params['slug'];
    axios.get(`${process.env.REACT_APP_API_URL}/api/collections/get/leadership?filter[name_slug]=${slug}`)
      .then(response => {
        const member = response.data.entries[0];
        setLeadershipMember(member);
      })
      .catch(
        error => console.log('error', error)
      )
  }, []);
  return (
    <Fragment>
      {leadershipMember._id ?
        <Fragment>
          <Head title={`Connatix - ${leadershipMember.name}`} />
          <Container>
            <Breadcrumbs {...props} currentPositionText={leadershipMember.name}/>

            <main className={styles['member-container']}>
              <div className={styles['member-header']}>
                <div className={styles['member__photo-container']}>
                  <img src={`${process.env.REACT_APP_API_URL}/${leadershipMember.avatar.path}`}
                       className={styles['member__photo']} alt=""/>
                </div>
                <div className={styles['member-details']}>
                  <h2 className={styles['member__name']}>{leadershipMember.name}</h2>
                  <p className={styles['member__position']}>{leadershipMember.position}</p>

                  <div className={styles['member__social-links']}>
                    {leadershipMember.linkedin ?
                      <a href={leadershipMember.linkedin} target="_blank" rel="noopener noreferrer"
                         className={styles['member__social-link']}>
                        <img src={linkedIn} alt="LinkedIn"/>
                      </a> : undefined
                    }

                    {leadershipMember.twitter ?
                      <a href={leadershipMember.twitter} target="_blank" rel="noopener noreferrer"
                         className={styles['member__social-link']}>
                        <img src={twitter} alt="Twitter"/>
                      </a> : undefined
                    }
                  </div>
                </div>
              </div>

              <div className={styles['member-description']}>
                <ContentCMS data={leadershipMember.description}/>
              </div>
            </main>
          </Container>
        </Fragment>
        :
        <Loading/>
      }
    </Fragment>
  );
};

export default LeadershipMemberPage;