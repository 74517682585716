import React from 'react';
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";

import './CustomCookiesConsent.scss';

const CustomCookiesConsent = () => {
  const isEU = window.cnxEU;

  return(
    <CookieConsent
      location="bottom"
      containerClasses={'cookies-consent-container'}
      buttonText={isEU ? 'Yes, I agree' : 'Got It'}
      buttonClasses={'cookies-consent-buttons'}
      onAccept={() => {localStorage.setItem('cookiesConsent', 'true')}}
      enableDeclineButton={isEU}
      declineButtonClasses={'cookies-consent-buttons'}
      declineButtonText="No, I decline"
      onDecline={() => {localStorage.setItem('cookiesConsent', 'false')}}
    >
      This website uses cookies to give you a better user experience.
      <Link to='/privacy-policy' className="cookies-consent-link">LEARN MORE</Link>
    </CookieConsent>
  )
};

export default CustomCookiesConsent;