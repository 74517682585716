import React, { Fragment, useState, useEffect } from 'react';
import axios from 'axios';

import './index.scss';

import OneColHeaderContent from "../../../components/OneColHeaderContent/OneColHeaderContent";
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs";
import SectionTitle from "../../../components/SectionTitle/SectionTitle";

import titleDecoration from '../../../assets/wave-line-horizontal.svg';
import ReasonsList from "./components/ReasonsList/ReasonsList";
import Container from "../../../hoc/Container/Container";
import OpenPositionsList from "./components/OpenPositionsList/OpenPositionsList";
import TitleAnimation from "./components/TitleAnimation/TitleAnimation";
import GalleryCarousel from "./components/GalleryCarousel/GalleryCarousel";
import CareerBlogList from "./components/CareerBlogList/CareerBlogList";

import headerBackground from '../../../assets/careers/header-background.mp4';
import blogListBackground from '../../../assets/careers/blog-list-background.png';
import openPositionsBackground from '../../../assets/careers/open-positions-bkg.png';
import reasonsBackground from '../../../assets/careers/reasons-background.png';
import Head from "../../../layout/Head/Head";

const CareersPage = (props) => {
  const boardToken = 'jwp';

  const [careerGallery, setCareerGallery] = useState([]);
  const [careerBlogPosts, setCareerBlogPosts] = useState([]);
  const [openPositions, setOpenPositions] = useState([]);
  const [careerLocations, setCareerLocations] = useState([]);
  const [careerDepartments, setCareerDepartments] = useState([]);

  // GET Carousel Gallery
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/singletons/get/careersGallery`)
      .then(response => {
        setCareerGallery(response.data.gallery ? response.data.gallery : []);
      });

    axios.get(`${process.env.REACT_APP_API_URL}/api/collections/get/blog?filter[careerRelatedPost]=true`)
      .then(response => {
        setCareerBlogPosts(response.data.entries ? response.data.entries : []);
      });

    axios.get(`https://boards-api.greenhouse.io/v1/boards/${boardToken}/offices`)
      .then(response => {
        const offices = response.data.offices.map(office => {
          office.departments = office.departments.filter(dept => dept.jobs.length > 0);
          return office;
        }).filter(office => office.departments.length > 0);
        setCareerLocations(offices);
      });

    axios.get(`https://boards-api.greenhouse.io/v1/boards/${boardToken}/departments`)
      .then(response => {
        setCareerDepartments(response.data.departments.filter(dept => dept.jobs.length > 0));
      });


    axios.get(`https://boards-api.greenhouse.io/v1/boards/${boardToken}/jobs`)
      .then(response => {
        setOpenPositions(response.data.jobs ? response.data.jobs : []);
      });
  }, []);

  return(
    <Fragment>
      <Head title="Connatix - Careers" />
      <header className={'careers-header'}>
        <video autoPlay loop muted playsInline className={'careers-header__video'}>
          <source src={headerBackground} type="video/mp4"/>
        </video>
        <div className={'careers-header__content'}>
          <Container>
            <Breadcrumbs {...props}/>
            <OneColHeaderContent
              title='Ready to create with us?'
              description="At Connatix, we don't hire candidates - we hire team players. We hire innovators. We hire creators.
                    Sound like you?"
              inlineScroll={true}
              inlineScrollOffset={-100}
              inlineTarget="open-positions-list"
              inlineScrollText="Join Us"
            />
          </Container>
        </div>
      </header>
      <main>
        <section>
          <Container>
            <TitleAnimation words={['Innovators', 'Creators', 'Disruptors', 'Storytellers']} />
            {careerGallery.length ?
              <GalleryCarousel gallery={careerGallery}/>
              : undefined
            }
          </Container>
        </section>
        <section className={'careers-blog-section'} id='meet-the-team'>
          <SectionTitle
            title="Meet The Team"
            textCentered={true}
            textColor='#000000'
            textBackground='#F6F9FD'
            textDecoration={titleDecoration}
            backgroundImage={blogListBackground}
          />
          {careerBlogPosts ?
            <CareerBlogList careerBlogList={careerBlogPosts} redirectUrl='/blog'/> :
            undefined }
        </section>
        <section>
          <SectionTitle
            title="Why we love it here"
            textCentered={true}
            textColor='#000000'
            textBackground='#F6F9FD'
            textDecoration={titleDecoration}
            backgroundImage={reasonsBackground}
          />
          <Container>
            <ReasonsList/>
          </Container>
        </section>
        <section id="open-positions-list">
          <SectionTitle
            title="Open Positions"
            textCentered={true}
            textColor='#000000'
            textBackground='#F6F9FD'
            textDecoration={titleDecoration}
            backgroundImage={openPositionsBackground}
          />
          {openPositions.length ? <OpenPositionsList positions={openPositions} departments={careerDepartments} locations={careerLocations}/> : undefined}
        </section>
      </main>
    </Fragment>
  );
};

export default CareersPage;