import React from 'react';
import './Carousel.scss';

import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";

import TwoColumnsTextSection from "../TwoColumnsTextSection/TwoColumnsTextSection";

const Carousel = ({carouselItems, sectionOptions}) => {

  const items = carouselItems.map(item => {
    let button;

    if (item.ButtonText && item.ButtonUrl) {
      button = <a href={item.ButtonUrl} className="button" target={item.NewTab ? '_blank' : '_self'} >{item.ButtonText}</a>;
    }

    return <TwoColumnsTextSection
    image={`${process.env.REACT_APP_API_URL}${item.Image.path}`}
    imageBackgroundMargin={sectionOptions.imageBackgroundMargin}
    hasPaddingBottom={false}
    direction={sectionOptions.direction}
    backgroundColor={sectionOptions.backgroundColor}
    descriptionDecorationColor="#FFCC00"
    description={item.TitleText}
    info={item.SubText}
  >
   {button}
  </TwoColumnsTextSection>

  });


  return (
    <section className="carousel-section" style={{backgroundColor: sectionOptions.backgroundColor }} >
      {sectionOptions.decorativeImage ?
      <img id={sectionOptions.decorativeImageId} className='decorative__image' src={sectionOptions.decorativeImage} alt=""/>          
      : undefined }
      <style>{sectionOptions.decorativeImageStyle}</style>
      <AliceCarousel
        autoPlay={true}
        autoPlayStrategy="none"
        autoPlayInterval={5000}
        disableButtonsControls
        infinite
        items={items}/>
    </section>
  );
};

export default Carousel;