import React, { Component } from 'react';
import PropTypes from 'prop-types';

import * as styles from './TitleAnimation.module.scss';

class TitleAnimation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      words: [],
      word: '',
      index: 0
    }
  }

  componentDidMount() {
    // Setting the initial State
    this.setState(
      {
        words: this.props.words,
        word: this.props.words[0]
      }
    );

    this.interval = setInterval(this.changeWord, 3000)
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  changeWord = () => {
    if (this.state.index >= this.state.words.length - 1) {
      this.setState({
        index: 0
      });
    } else {
      this.setState({
        index: this.state.index + 1
      })
    }
    this.setState({word: this.state.words[this.state.index]});
  };

  render() {
    return (
      <h2 className={styles['animated-title']}>
        We Are
        <span className={styles['animated-title__word']}>{this.state.word}</span>
      </h2>
    )
  }
}

TitleAnimation.propTypes = {
  words: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default TitleAnimation;