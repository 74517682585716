import React, { Fragment, useState, useEffect } from 'react';
import axios from 'axios';
import * as styles from './index.module.scss';
import LazyLoad from 'react-lazyload';

import CoverCard from "../../../components/CoverCard/CoverCard";

import Container from "../../../hoc/Container/Container";
import Grid from "../../../hoc/Grid/Grid";
import Card from "../../../hoc/Card/Card";
import Header from '../../../hoc/Header/Header';
import MainArticle from "../../../components/MainArticle/MainArticle";
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs";
import Head from "../../../layout/Head/Head";

const BlogPage = (props) => {
  const [mainBlogArticle, setMainBlogArticle] = useState([]);
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/collections/get/blog`)
      .then(response => {
        const articles = response.data.entries ? response.data.entries : [];

        setMainBlogArticle(articles.filter(article => article.mainArticle));
        setArticles(articles.filter(article => !article.mainArticle));
      })
  }, []);

  articles.sort((a, b) => (a.date < b.date) ? 1 : -1);

  return(
    <Fragment>
      <Head title="Connatix - Blog"/>
      <Header>
        <Breadcrumbs {...props} hasBackground={false}/>
        {mainBlogArticle.length ?
          <MainArticle
            mainArticle={mainBlogArticle[0]}
            url={props.match.url}
            buttonText="Read More"
            hasDecorations={true}
          />
          :
          undefined
        }
      </Header>
     <main className={styles['blog-list']}>
        <Container>
          <Grid>
            {articles.map(article =>(
              <LazyLoad height={100} key={article._id}>
                <Card>
                  <CoverCard {...article} url={props.match.url} buttonText="Read More"/>
                </Card>
              </LazyLoad>
            ))}
          </Grid>
        </Container>
      </main>
    </Fragment>
    );
};

export default BlogPage;