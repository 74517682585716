import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import * as Scroll from 'react-scroll';

import styles from './OneColHeaderContent.module.scss'

let InlineLink = Scroll.Link;

const OneColHeaderContent = props => (
  <div className={styles['header__content']}>
    <h1 className={styles['header__title']}>{props.title}</h1>
    <p className={styles['header__description']}>{props.description}</p>
    {
      props.buttonText ?
        <Link to={props.redirectPath} className={styles['header__button']}>{props.buttonText}</Link>
        : undefined
    }
    {
      props.inlineScroll ?
        <InlineLink to={props.inlineTarget}
                    spy={true}
                    offset={props.inlineScrollOffset}
                    smooth={true}
                    className={styles['header__button']}>{props.inlineScrollText}</InlineLink>
        : undefined
    }
  </div>
);

OneColHeaderContent.propTypes = {
  titleDecoration: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  buttonText: PropTypes.string,
  redirectPath: PropTypes.string
};

export default OneColHeaderContent;