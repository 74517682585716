import React from 'react';
import * as styles from './OfficesList.module.scss';
import Grid from "../../../../hoc/Grid/Grid";
import Container from "../../../../hoc/Container/Container";
import Office from "../Office/Office";

import officeNewYork from '../../../../assets/contact/office-new-york.png';
import officeNewYork2x from '../../../../assets/contact/office-new-york@2x.png';
import officeCluj from '../../../../assets/contact/office-cluj.png';
import officeCluj2x from '../../../../assets/contact/office-cluj@2x.png';
import officeTelAviv from '../../../../assets/contact/office-tel-aviv.png';
import officeTelAviv2x from '../../../../assets/contact/office-tel-aviv@2x.png';

const offices = [
  {
    city: 'New York',
    address: '666 Broadway, 10th Floor, New York, NY 10012',
    location: officeNewYork,
    location2x: officeNewYork2x
  },
  {
    city: 'Cluj-Napoca',
    address: '36 Ploiesti Street, 2nd Floor, Cluj-Napoca, Romania',
    location: officeCluj,
    location2x: officeCluj2x
  },
  {
    city: 'Tel Aviv',
    address: '114 Yigal Alon Street, WeWork ToHa 11th Floor, Tel Aviv, Israel 6744320',
    location: officeTelAviv,
    location2x: officeTelAviv2x
  }
];

const OfficesList = props => (
  <div className={styles['offices-list']}>
    <Container>
      <h3 className={styles['offices-list__title']}>Our offices.</h3>
      <Grid>
        {offices.map((office, index) => (
          <Office key={index}
                  city={office.city}
                  address={office.address}
                  location={office.location}
                  location2x={office.location2x}/>
        ))}
      </Grid>
    </Container>
  </div>
);

export default OfficesList;