import React, { Component } from 'react';

import * as styles from './VideoSection.module.scss';

import videoImg from '../../../../assets/homepage/video-section-img.png';
import { Link } from "react-router-dom";

class VideoSection extends Component {
  componentDidMount() {
    // Adding our Video Player Unit
    const script = document.createElement("script");
    script.id = "238df12a920e4080b3a481db87ea29f4";
    script.innerText = (`cnx.cmd.push(function() { cnx({ playerId: 'a5173d6b-4fd1-4cd6-b731-ef156b463ff5', settings: { customization: { alwaysDisplayControls: true } } }).render('238df12a920e4080b3a481db87ea29f4'); });`);

    const playerContainer = document.getElementById('video-player');
    playerContainer.append(script)
  }

  render() {
    return (
      <section className={styles['homepage-video']} id="homepage-player">
        <div className={styles['homepage-container']}>
          <div className={styles['homepage-video-container']}>
            <div className={styles['homepage-video__content']}>
              {/* <ScrollSection elementsActive={true}/> */}
              <div className={styles['section-tag']}>For Publishers</div>
              <h2 className={styles['homepage-section__title']}>
              Deliver a fast & flawless viewing experience
              </h2>
              <p className={styles['homepage-section__subtitle']}>
              Keep audiences engaged with a versatile, lightweight, and fully customizable player.
              </p>
              <Link to="/video-delivery" className={styles['homepage-video__link']}>LEARN MORE</Link>
            </div>
            <div className={styles['homepage-video__img-container']}>
              <img src={videoImg} alt="" className={styles['homepage-video__img']}/>
              <div className={styles['homepage-video__video-player']}>
                <div id="video-player"/>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default VideoSection;
