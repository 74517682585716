import React from 'react';
import TextEllipsis from "react-text-ellipsis";

const TextClamp = (props) => (
  <TextEllipsis lines={props.lines}
                ellipsisChars={props.ellipsis}
                debounceTimeoutOnResize={200}
                tag={props.tag}
                tagClass={props.className}>
    {props.text}
  </TextEllipsis>
);

export default TextClamp;
