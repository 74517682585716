import React, { useEffect } from 'react';
import { Link } from "react-scroll/modules";
import "../../../styles/Header.scss";
import parse from 'html-react-parser';
import headerBkg from "../../../../../assets/advertisers/advertisers_header_background.png";
import Header from "../../../../../hoc/Header/Header";


const ContextualHeader = ({ headerTitle, headerDesc }) => {

     
    
    useEffect(() => {
        // Adding our Video Player Unit
        const script = document.createElement("script");
        script.id = "815777650e154bd59a238c0cb1a2f584";
        script.innerHTML = (`cnx.cmd.push(function() { 
            cnx({ 
                playerId: "572af004-cfbb-4152-b928-2731be8c611e",  
                mediaId: "31402482-2030-4fc7-994d-cbddb411761b"
            }).render("815777650e154bd59a238c0cb1a2f584");});`);
        const playerContainer = document.getElementsByClassName('header-content__player')[0];
        playerContainer.appendChild(script);
    }, []);



    return (
        <Header backgroundImage={headerBkg}>
            <div className='header-content contextual'>
                <div className='header-content__info-container '>
                    <div className='header-content__info'>
                        <h2 className='header-content__title'>{parse(headerTitle)}</h2>
                        <h1 className='header-content__description'>
                            {headerDesc}
                        </h1>
                        <Link to="contextual-contact"
                            spy={true}
                            smooth={true}
                            className='header-content__btn'>
                            Get in touch
                        </Link>
                    </div>
                </div>
                <div className='header-content__player-container'>
                    <div className='header-content__player-background'>
                        <div className='header-content__player'>
                        </div>
                    </div>
                </div>
            </div>
        </Header>
    );
}



export default ContextualHeader;