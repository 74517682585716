import React from 'react';

import linkedIn from '../../../../../assets/linkedIn-icon.svg';
import twitter from '../../../../../assets/twitter-icon.svg';

import styles from './LeadershipCard.module.scss';
import { Link } from "react-router-dom";
import Card from "../../../../../hoc/Card/Card";

const LeadershipCard = props => (
  <Card>
    <div className={styles['leadership-card']}>
      <div className={styles['leadership-card__avatar-container']}>
        <Link to={`leadership/${props.name_slug}`} className={styles['leadership-card__avatar-link']}>
          <img src={`${process.env.REACT_APP_API_URL}/${props.avatar.path}`}
               alt={props.name}
               className={styles['leadership-card__avatar']}/>
        </Link>
      </div>
      <div className={styles['leadership-card__content']}>
        <p className={styles['leadership-card__name']}>
          {props.name}
        </p>
        <span className={styles['leadership-card__name-decorator']}/>
        <p className={styles['leadership-card__position']}>
          {props.position}
        </p>
      </div>

      <div className={styles['leadership-card__footer']}>
        <div className={styles['leadership-card__social-links']}>
          {
            props.linkedin ?
              <a href={props.linkedin} target="_blank" rel="noopener noreferrer"
                 className={styles['leadership-card__social-link']}>
                <img src={linkedIn} alt="LinkedIn"/>
              </a> : undefined
          }

          {
            props.twitter ?
              <a href={props.twitter} target="_blank" rel="noopener noreferrer"
                 className={styles['leadership-card__social-link']}>
                <img src={twitter} alt="Twitter"/>
              </a> : undefined
          }
        </div>

        <Link to={`leadership/${props.name_slug}`} className={styles['leadership-card__more-info']}>
          BIO
          <i className="material-icons">
            arrow_forward
          </i>
        </Link>
      </div>
    </div>
  </Card>
);

export default LeadershipCard;
