import React from 'react';
import PropTypes from 'prop-types';
import Card from "../../../../../hoc/Card/Card";

import styles from './OpenPosition.module.scss';
import { Link } from "react-router-dom";

const OpenPosition = props => (
  <Card>
    <Link to={{ pathname: `careers/gh_jid=${props.jobId}`, search: `?pos=${props.title}` }}
      className={styles['position']}>
      <p className={styles['position__title']}>
        {props.title}
      </p>
      <p className={styles['position__description']}>
        {props.description}
      </p>
      <div className={styles['position__redirect']}>
        <span>READ MORE</span>
        <i className="material-icons">
          arrow_forward
        </i>
      </div>
    </Link>
  </Card>
);

OpenPosition.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  jobId: PropTypes.number.isRequired
};

export default OpenPosition;