import React, { Fragment, useState } from 'react';
import './PricingTable.scss';
import icInfo from '../../../../assets/svg/ic_info.svg';
import icCaretDown from '../../../../assets/svg/ic_caret_down.svg';
import icCaretUp from '../../../../assets/svg/ic_caret_up.svg';
import icCheckmark from '../../../../assets/svg/ic_checkmark.svg';
import icRemove from '../../../../assets/svg/ic_remove.svg';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import { Link } from "react-scroll/modules";


const PricingTable = ({ tableData, isTS, pricingOptionsData, isBottomHeaderVisible, headerInfo }) => {
    const [sectionVisible, setSectionVisible] = useState({ 0: true }); //set the first table section to be visible by default
    const isSectionVisible = (index) => sectionVisible[index];





    const sectionTitles = [];
    tableData.forEach(item => {
        sectionTitles.push(item.sectionTitle);
    })
    const uniqueSectionTitles = [...new Set(sectionTitles)];

    const sectionIconPath = (title) => {
        const section = tableData.find(item => item.sectionTitle === title);
        return section.sectionIcon ? section.sectionIcon.path : ''; //substitute for optional chaining which is not supported .i.e. substitute for section.sectionIcon.path
        // return section?.sectionIcon?.path;
    };

    const sectionRows = (title, index) => {
        return tableData.filter(item => item.sectionTitle === title).map(item => {
            return (
                <tr key={item._id} className={isSectionVisible(index) ? 'visible' : 'hidden'}>
                    <td className='features-col'>
                        <div className='cell-container'>
                            {item.featureName}
                            <span>
                                <img src={icInfo} alt='info icon' />
                                <Tooltip text={item.featureDescription} isTS={isTS} />
                            </span>
                        </div>

                    </td>
                    {isTS ? <Fragment>
                        <td>
                            <div className='cell-container'>
                                {item.isAvailableUnderEssentials ? <img src={icCheckmark} alt="checkmark icon" /> : <img src={icRemove} alt="remove icon" />}
                            </div>
                        </td>
                        <td>
                            <div className='cell-container'>
                                {item.isAvailableUnderPremium ? <img src={icCheckmark} alt="checkmark icon" /> : <img src={icRemove} alt="remove icon" />}
                            </div>
                        </td>
                        <td>
                            <div className='cell-container'>
                                {item.isAvailableUnderEnterprise ? <img src={icCheckmark} alt="checkmark icon" /> : <img src={icRemove} alt="remove icon" />}
                            </div>
                        </td>
                    </Fragment>

                        :
                        <td>
                            <div className='cell-container'>
                                {item.isAvailableUnderAdSupported ? <img src={icCheckmark} alt="checkmark icon" /> : <img src={icRemove} alt="remove icon" />}
                            </div>
                        </td>

                    }

                </tr>
            )
        })
    };

    const toggleSectionVisibility = (index) => () => {
        setSectionVisible({
            ...sectionVisible,
            [index]: !isSectionVisible(index)
        });
    };


    return (
        <Fragment>
            <div className='pricing-packages-container'>
                <h3>{isTS ? headerInfo.sassHeadline : headerInfo.adSupportedHeadline}</h3>
                <p className='small-size-description'>{isTS ? headerInfo.sassDesc : headerInfo.adSupportedDesc}</p>
                <div className='pricing-cards-container'>
                    {isTS ?
                        <Fragment>
                            {
                                pricingOptionsData.map((option, index) => {
                                    return (

                                        <div key={option.planName} className="plan-name">
                                            <div className={index === 0 ? "thead-card first" : index === 1 ? 'thead-card second' : 'thead-card third'}>
                                                <div>
                                                    <h3>{option.planName}</h3>
                                                    <span>{option.planSize}
                                                        {index === 0 ? <div className='amount'>{option.planPrice}</div> : null}
                                                    </span>
                                                </div>


                                                <Link to="pricing-contact"
                                                  
                                                    spy={true}
                                                    smooth={true}
                                                    className='cta-btn'>
                                                    Contact Us
                                                </Link>
                                            </div>

                                        </div>
                                    );
                                })
                            }
                        </Fragment>
                        :
                        <div className="plan-name">
                            <div className='thead-card third ad-supported'>
                                <div>
                                    <h3>{pricingOptionsData.length > 0 ? pricingOptionsData[2].planName : ''}</h3>
                                    <span>{pricingOptionsData.length > 0 ? pricingOptionsData[2].planSize : ''}

                                    </span>
                                </div>


                                <Link to="pricing-contact"
                              
                                    spy={true}
                                    smooth={true}
                                    className='cta-btn'>
                                    Contact Us
                                </Link>
                            </div>

                        </div>
                    }
                </div>



            </div>
            <table className='pricing-table'>
                <thead>
                    <tr>
                        <th className={isBottomHeaderVisible ? '' : 'small-title'}>
                            <div>
                                <span className='packages-word'>{isTS ? headerInfo.sassHeadline : headerInfo.adSupportedHeadline}</span>
                                <span className='packages-word'> {isBottomHeaderVisible ? isTS ? headerInfo.sassDesc : headerInfo.adSupportedDesc : null}</span>
                            </div>
                        </th>
                        {
                            isTS ?
                                <Fragment>
                                    {
                                        pricingOptionsData.map((option, index) => {
                                            return (
                                                <th key={option.planName} className={isBottomHeaderVisible ? '' : 'small'}>
                                                    <div className={index === 0 ? "thead-card first" : index === 1 ? 'thead-card second' : 'thead-card third'}>
                                                        <div>
                                                            <h3>{option.planName}</h3>
                                                            <span>{option.planSize}
                                                                {index === 0 ? <div className='amount'>{option.planPrice}</div> : null}
                                                            </span>
                                                        </div>


                                                        <Link to="pricing-contact"
                                                           offset={-150}
                                                            spy={true}
                                                            smooth={true}
                                                            className='cta-btn'>
                                                            Contact Us
                                                        </Link>
                                                    </div>

                                                    <div className={index === 0 ? "small-plan-name first" : index === 1 ? 'small-plan-name second' : 'small-plan-name third'}>
                                                        {option.planName}
                                                        <div></div>
                                                    </div>

                                                </th>
                                            );
                                        })
                                    }

                                </Fragment> :
                                <th className={isBottomHeaderVisible ? '' : 'small'}>
                                    <div className='thead-card third ad-supported'>
                                        <h3>{pricingOptionsData.length > 0 ? pricingOptionsData[2].planName : ''}</h3>
                                        <span>{pricingOptionsData.length > 0 ? pricingOptionsData[2].planSize : ''}</span>
                                        <Link to="pricing-contact"
                                           offset={-150}
                                            spy={true}
                                            smooth={true}
                                            className='cta-btn'>
                                            Contact Us
                                        </Link>
                                    </div>
                                    <div className='small-plan-name third'>
                                        {pricingOptionsData.length > 0 ? pricingOptionsData[2].planName : ''}
                                        <div></div>
                                    </div>
                                </th>
                        }


                    </tr>
                </thead>

                <tbody>

                    {uniqueSectionTitles.map((title, index) => {

                        return (
                            <Fragment >
                                <tr className='section-header-row' onClick={toggleSectionVisibility(index)}>
                                    <td colSpan={'4'} >
                                        <div className='section-header'>
                                            <div>
                                                <img className='section-header__icon' src={`${process.env.REACT_APP_API_URL}${sectionIconPath(title)}`} alt="section icon" />
                                                {title}
                                            </div>

                                            <span>{isSectionVisible(index) ? <img src={icCaretUp} alt="caret down" /> : <img src={icCaretDown} alt="caret down" />}</span>
                                        </div>
                                    </td>
                                </tr>
                                {sectionRows(title, index)}
                            </Fragment>
                        );
                    })}

                </tbody>


            </table>
        </Fragment>

    )

}


export default PricingTable;