import React, { Fragment, useState, useEffect } from 'react';
import axios from "axios";

import styles from './index.module.scss';

import ContentCMS from "../../../components/ContentCMS/ContentCMS";
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs";
import Container from "../../../hoc/Container/Container";
import Head from "../../../layout/Head/Head";
import Loading from "../../../components/Loading/Loading";

const PrivacyPolicyPage = props => {
  const [privacyPolicy, setPrivacyPolicy] = useState([]);
   
  const scrollFunc = () => {
    const generalSectionHeader = document.querySelector('#generalSectionHeader');
    const videoPlatformSectionHeader = document.querySelector('#videoPlatformSectionHeader');
 
    const scrollTarget1 = document.querySelector('#scrollTarget');
    const scrollTarget1Position = scrollTarget1.getBoundingClientRect().top;

    const scrollTarget2 = document.querySelector('#scrollTarget2');
    const scrollTarget2Position = scrollTarget2.getBoundingClientRect().top;
    

    if (scrollTarget1Position <= 1074) {//adjust this to determine when the General Privacy Policy Header starts stickying to the top (if the value is smaller the header will become sticky later, if the value is bigger the header will become sticky earlier)
      generalSectionHeader.classList.add('fixed-to-top');
    } else {
      generalSectionHeader.classList.remove('fixed-to-top')
    }

    if (scrollTarget2Position <= 950) {//adjust this to determine when the Video Platform Privacy Policy Header starts stickying to the top (if the value is smaller the header will become sticky later, if the value is bigger the header will become sticky earlier)
      videoPlatformSectionHeader.classList.add('fixed-to-top');
    } else {
      videoPlatformSectionHeader.classList.remove('fixed-to-top');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollFunc);
    axios.get(`${process.env.REACT_APP_API_URL}/api/singletons/get/newPrivacy`)
      .then(response => {
        setPrivacyPolicy(response.data.content ? response.data.content : []);
      })
      .catch(error => console.log('error', error));

      return () => {
        window.removeEventListener('scroll', scrollFunc)
      }
  }, []);




  



  return (
    <Fragment>
      {privacyPolicy.length ?
        <Fragment>
          <Head title='Connatix - Privacy Policy' />
          <Container>
            <Breadcrumbs {...props} currentPositionText='Website Privacy Notice' />
            <main className={styles['privacy-policy']}>
              <ContentCMS data={privacyPolicy} />
            </main>
          </Container>
        </Fragment>
        :
        <Loading />
      }
    </Fragment>
  );
};

export default PrivacyPolicyPage;
