import React, { Component } from 'react';
import { Link } from "react-scroll/modules";
import "../../../styles/Header.scss";

import headerBkg from "../../../../../assets/common-header-bg.png";
// import headerLogo from "../../../../../assets/adserver/adserver_logo.png";
import Header from "../../../../../hoc/Header/Header";

class AdServerHeader extends Component {

  componentDidMount() {
      // Adding our Video Player Unit
      const script = document.createElement("script");
      script.id = "f952b2d0513c40dda8ac3b220047b4ce";
      script.innerHTML = (`cnx.cmd.push(function() { cnx({ playerId: "3ea6a63e-658f-4fe0-8d40-07dab2582e4a"}).render("f952b2d0513c40dda8ac3b220047b4ce");});`);
      const playerContainer = document.getElementsByClassName('header-content__player')[0];
      playerContainer.appendChild(script);
  }

  render() {
    return (
      <Header backgroundImage={headerBkg}>
        <div className='header-content video-analytics'>
        <div className='header-content__info-container'>
            <div className='header-content__info'>
              {/* <img className='header-content__logo' src={headerLogo} alt=""/> */}
              <div className='header-tag'>
                Video Analytics
              </div>
              <h1 className='header-content__title'> Learn what works with data-driven insights</h1>
              <h2 className='header-content__description'>Optimize video strategies with analytics that tell a story</h2>

              <Link to="adserver-contact"
                    spy={true}
                    smooth={true}
                    className='header-content__btn'>
                Get in touch
              </Link>
            </div>
          </div>
          <div className='header-content__player-container'>
            <div className='header-content__player-background'>
              <div className='header-content__player'>
              </div>
            </div>
          </div>
        </div>
      </Header>
    )
  }
}

export default AdServerHeader;