import React, { Component, Fragment } from 'react';
import './index.scss';
import Head from "../../../../../layout/Head/Head";

export default class JobDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: 'Connatix is Hiring'
    }
  }

  componentDidMount() {
    window.Grnhse.Iframe.load(this.props.match.params.id);
    const params = new URLSearchParams(this.props.location.search);
    this.setState({
      title: params.get('pos') ? `Connatix is Hiring - ${params.get('pos')}` : ''
    });
  }

  render() {
    return (
      <Fragment>
        <Head title={this.state.title} />
        <div id="grnhse_app" />
      </Fragment>
    );
  }
}