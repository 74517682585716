import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import * as styles from './TwoColumnsTextSection.module.scss';
import FlexContainer from '../FlexContainer/FlexContainer';


class TwoColumnsTextSection extends Component {
  constructor(props) {
    super(props);

    this.sectionBackgroundStyles = {
      backgroundColor: props.backgroundColor ? props.backgroundColor : '',
      backgroundImage: props.backgroundImage ? `url(${props.backgroundImage})` : '',
      backgroundRepeat: props.backgroundImage ? 'no-repeat' : '',
      backgroundSize: props.backgroundImage ? 'cover' : ''
    };
  }


  componentDidMount() {
    // Adding our Video Player Unit
    if (this.props.playerScript && this.props.playerScriptId) {
      const script = document.createElement("script");
      script.id = this.props.playerScriptId
      script.innerText = this.props.playerScript;

      const playerContainer = document.getElementById("player-container");
      playerContainer.appendChild(script);
    }
  }

  render() {
    return (
      <div style={this.sectionBackgroundStyles}
        className={`${styles['two-column-text-section']} 
          ${this.props.hasPaddingBottom ? styles['two-column-text-section--padding-bottom'] : ''}`}>
        <FlexContainer direction={`${this.props.direction}`} backgroundColor={this.props.backgroundColor}>
          <div className={styles['section__text']}>
            {this.props.title ? <p className={styles['section__title']}>{this.props.title}</p> : undefined}

            <div className={styles['section__description-container']}>
              {this.props.descriptionDecorationColor ?
                <div className={styles['section__description-decoration']}
                  style={{ backgroundColor: this.props.descriptionDecorationColor }} />
                : undefined
              }
              <h4 className={styles['section__description']}
                style={{ color: this.props.descriptionColor ? this.props.descriptionColor : undefined }}
              >{this.props.description}</h4>
            </div>
            {this.props.info ?

              <p className={styles['section__info']}
                style={{ color: this.props.infoColor ? this.props.infoColor : undefined }}>
                {this.props.info}
              </p> : undefined
            }
            {this.props.bulletList && this.props.bulletList.length > 0 ?
              <ul>
                {
                  this.props.bulletList.map((item) => (
                    <li key={item}>{item}</li>
                  ))
                }

              </ul> : undefined
            }
            {this.props.decoratedBulletList && this.props.decoratedBulletList.length > 0 ?
              <ul>
                {
                  this.props.decoratedBulletList.map((item) => (
                    <li key={item}><span>{item.title}</span>: {item.desc}</li>
                  ))
                }

              </ul> : undefined
            }
            


            {
              this.props.secondInfo ? <p className={styles['section__info']}
                style={{ color: this.props.infoColor ? this.props.infoColor : undefined }}>{this.props.secondInfo}</p> : ''
            }
            {this.props.buttonText && this.props.buttonLink ?
              <Link to={`${this.props.buttonLink}`} className={styles['button']}>{this.props.buttonText}</Link>
              : undefined
            }
            {this.props.children}
          </div>

          <div className={styles['section__img']} style={{ margin: this.props.imageMargin, maxWidth: this.props.sectionMaxWidth, width: this.props.sectionWidth }}>
            {this.props.image ?
              <img src={this.props.image} alt="" style={{ margin: this.props.imageBackgroundMargin, width: this.props.imageWidth, maxWidth: this.props.imageMaxWidth }} className={this.props.playerStyles ? "image" : undefined} />
              : undefined
            }
            {this.props.playerScript && this.props.playerScriptId ?
              <div id="player-container" className="player"></div> : undefined
            }
          </div>

          <img id={this.props.decorativeImageId} className={styles['decorative__image']} src={this.props.decorativeImage} alt="" />

          <style>{this.props.playerStyles}</style>
          <style>{this.props.decorativeImageStyle}</style>
        </FlexContainer>
      </div>
    )
  }
}

TwoColumnsTextSection.propTypes = {
  playerScript: PropTypes.string,
  playerScriptId: PropTypes.string,
  playerStyles: PropTypes.string,
  image: PropTypes.string,
  imageMobile: PropTypes.string,
  imageMargin: PropTypes.string,
  imageBackgroundMargin: PropTypes.string,
  imageWidth: PropTypes.string,
  imageMaxWidth: PropTypes.string,
  sectionWidth: PropTypes.string,
  sectionMaxWidth: PropTypes.string,
  backgroundImage: PropTypes.string,
  decorativeImage: PropTypes.string,
  decorativeImageId: PropTypes.string,
  decorativeImageStyle: PropTypes.string,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
  direction: PropTypes.string,
  backgroundColor: PropTypes.string,
  title: PropTypes.string,
  descriptionDecorationColor: PropTypes.string,
  description: PropTypes.string,
  descriptionColor: PropTypes.string,
  info: PropTypes.string,
  bulletList: PropTypes.array,
  decoratedBulletList: PropTypes.array,
  infoColor: PropTypes.string,
  hasPaddingBottom: PropTypes.bool
};

export default TwoColumnsTextSection;