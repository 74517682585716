import React from 'react';
import PropTypes from 'prop-types';

import styles from './SectionTitle.module.scss';

import Container from "../../hoc/Container/Container";

const SectionTitle = (props) => {
  const backgroundStyles = {
    alignItems: 'center',
    backgroundImage: `url(${props.backgroundImage})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    display: 'flex',
    height: '500px'
  };

  const textBackgroundStyles = {
    backgroundColor: props.textBackground,
    padding: '16px'
  };

  return (
    <div style={props.backgroundImage ? backgroundStyles : undefined}>
      <Container>
        <div className={styles['section-title__title-container']}
             style={props.textCentered ? {alignItems: 'center'} : undefined}>
          <h2 className={styles['section-title__title']}
              style={
                Object.assign('',
                  props.textBackground ? textBackgroundStyles : undefined,
                  props.textColor ? {color: props.textColor} : undefined
                )
              }
          >
            {props.title}
          </h2>
          {props.textDecoration ?
            <img src={props.textDecoration} alt=''/> : undefined
          }

        </div>
      </Container>
    </div>
  );
};

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  textCentered: PropTypes.bool,
  textBackground: PropTypes.string,
  textColor: PropTypes.string,
  textDecoration: PropTypes.any,
  backgroundImage: PropTypes.string
};

export default SectionTitle;