import React, { useState, useEffect, Fragment } from 'react';
import Container from "../../hoc/Container/Container";
import Head from "../../layout/Head/Head";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import axios from "axios";
import ContentCMS from "../../components/ContentCMS/ContentCMS";

import * as styles from './index.module.scss';

const SubProcessorsPage = props => {
  const [subProcessors, setSubProcessors] = useState([]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/singletons/get/subProcessors`)
      .then(response => {setSubProcessors(response.data.content)})
      .catch(error => console.log('error', error));
  }, []);

  return (
    <Fragment>
      <Head title="Connatix - Sub-Processors"/>
      <Container>
        <Breadcrumbs {...props} currentPositionText='Sub Processors'/>
        <main className={styles['sub-processors']}>
          <ContentCMS data={subProcessors}/>
        </main>
      </Container>
    </Fragment>
  )
};

export default SubProcessorsPage;