import React, { Component } from 'react';
import styles from '../../index.module.scss';
import ArticleHeader from '../../components/article-header/ArticleHeader';
import ContentCMS from "../../../../../../../components/ContentCMS/ContentCMS";
import Container from "../../../../../../../hoc/Container/Container";

// Live Preview page used by CMS when adding a Blog Post
class BlogArticlePreviewPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      blogArticle: {}
    }
  }

  componentDidMount() {
    let self = this; // Created in order to be able to set the component state from inside the addEventListener
    window.addEventListener('message', function (event) {
      console.log('Message Received', event);
      self.setState({blogArticle: event.data.entry});
    }, false);
  }

  render() {
    const blogArticle = this.state.blogArticle;

    return(
      <Container>
        <article className={styles['article']} id="blog-article-preview">
          {blogArticle ? <ArticleHeader articleTitle={blogArticle.title}
                                        articleDate={blogArticle._created}
                                        articleCover={blogArticle.cover && blogArticle.cover.path}
                                        articleDescription={blogArticle.description}
                                        authorAvatar={blogArticle['authorAvatar'] && blogArticle['authorAvatar'].path}
                                        authorName={blogArticle['authorName']}
                                        authorPosition={blogArticle['authorPosition']}/> : undefined}

          {blogArticle['content'] ? <ContentCMS data={blogArticle.content}/> : undefined}
        </article>
      </Container>
    );
  }
}

export default BlogArticlePreviewPage;

