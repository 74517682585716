import React from 'react';
import './LogosGallery.scss';

import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";

const LogosGallery = ({ logosGallery, className, logosTitle, logosDescription }) => {

  const responsive = {
    0: { items: 2 },
    768: { items: 4 },
    1200: { items: 5 }
  };

  const logos = logosGallery.map(logo => (
    <img src={`${process.env.REACT_APP_API_URL}${logo.path}`} alt="" />
  ));

  return (
    <section className={className ? `homepage-logos-section ${className}` : 'homepage-logos-section'}>
      <div className='logos-info'>
        <h3 className="homepage-logos__title">{logosTitle}</h3>
        <p className='logos-description'>{logosDescription}</p>
      </div>

      <AliceCarousel
        autoPlay={true}
        autoPlayInterval={3000}
        disableButtonsControls
        responsive={responsive}
        items={logos} />
    </section>
  );
};

LogosGallery.defaultProps = {
  logosDescription: ''
}

export default LogosGallery;