import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";

import './PricingHeader.scss';



const PricingHeader = (props) => {
  const [headerInfo, setHeaderInfo] = useState({});
  const bottomHeaderRef = useRef();

  const setTechSass = (isTS) => {
    props.toggleTechSass(isTS);
  };


  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/singletons/get/pricingPageHeader`)
      .then(response => {
        setHeaderInfo(response.data ? response.data : {});
      })
      .catch(error => console.log('Pricing Header Error', error));
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
  
      props.setHeaderVisibility(entry.isIntersecting);
 
    });
    observer.observe(bottomHeaderRef.current)
  },[]);


  return (
    <div className='pricing-header'>
      <div className='pricing-header__top'>
        <div className='title'>
          <h1>{headerInfo.headline}</h1>
          <span>{headerInfo.subheader}</span>
        </div>

        <div className='buttons-toggle' ref={bottomHeaderRef}>
          <div className={props.isTS ? '' : 'active'} onClick={() => setTechSass(false)}>{headerInfo.buttonOne}</div>
          <div className={props.isTS ? 'active' : ''} onClick={() => setTechSass(true)}>{headerInfo.buttonTwo}</div>
        </div>
      </div>
   
    </div>
  )

};

export default PricingHeader;