import React, { Component } from 'react'
import * as styles from "./StoriesSection.module.scss";
import { Link } from "react-router-dom";

class StoriesSection extends Component {

  componentDidMount() {
    // Adding our Stories Unit
    const script = document.createElement("script");
    script.setAttribute("id", "d3cd45147df2434f926a46ab2da700ea");
    script.innerText = (`cnx.cmd.push(function() { cnx({ playerId: "6e41e5b3-6470-4af9-a2ec-757fd0e0e38d" }).render("d3cd45147df2434f926a46ab2da700ea"); });`);

    const storiesContainer = document.getElementById('story-player');
    storiesContainer.appendChild(script);
  }

  render() {
    return (
        <section className={styles['homepage-stories']} id="homepage-stories">
          <div className={styles['homepage-container']}>
            <div className={styles['homepage-stories-container']}>
              <div className={styles['homepage-stories__content']}>
                {/* <ScrollSection playspaceActive={true}/> */}
                <div className={styles['section-tag']}>For Advertisers</div>
                <h2 className={styles['homepage-section__title']}>
                Boost video performance with direct access to premium content               </h2>
                <p className={styles['homepage-section__subtitle']}>
                Reach engaged audiences at scale with inventory from thousands of sites and Deep Contextual targeting.                </p>  
                <Link to="/advertisers" className={styles['homepage-stories__link']}>Learn More</Link>
              </div>
              <div className={styles['homepage-stories__img-container']}>
                <div className={styles['homepage-stories__player']}>
                  <div id="story-player"></div>
                </div>
              </div>
            </div>
          </div> 
        </section>
    );
  }
}

export default StoriesSection;