import React from 'react';

import './Loading.scss';

const Loading = () => (
  <div className="spinner">
   {/* <div className="cube1"></div>
    <div className="cube2"></div>*/}
  </div>
);

export default Loading;