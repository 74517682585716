import React from 'react';
import PropTypes from 'prop-types';
import LeadershipCard from "../LeadershipCard/LeadershipCard";
import Container from "../../../../../hoc/Container/Container";
import * as styles from './LeadershipList.module.scss';
import Grid from "../../../../../hoc/Grid/Grid";

const LeadershipList = ({leadershipMembers, leadershipOwners}) => (
  <div className={styles['leadership-cards-list']}>
    <Container>
      <div className={styles['leadership-owners-list']}>
        {leadershipOwners.map((member, index) => (
          <LeadershipCard key={index} {...member}/>
        ))}
      </div>
      <Grid>
        {leadershipMembers.map((member, index) => (
          <LeadershipCard key={index} {...member}/>
        ))}
      </Grid>
    </Container>
  </div>
);

LeadershipList.propTypes = {
  leadershipMembers: PropTypes.array.isRequired,
  leadershipOwners: PropTypes.array.isRequired
};

export default LeadershipList;