import React, { Component } from 'react';
import { Link } from "react-scroll/modules";
import "../../../styles/Header.scss";

import headerBkg from "../../../../../assets/common-header-bg.png";
// import headerLogo from "../../../../../assets/playspace/playspace_logo.png";
import Header from "../../../../../hoc/Header/Header";

class PlayspaceHeader extends Component {

  componentDidMount() {
      // Adding our Video Player Unit
      const script = document.createElement("script");
      script.innerText = (`cnx.cmd.push(function() { cnx({ playerId: "5b1bf73b-13a5-4a9e-b626-68ceb6df86f2" }).render("f1f84a50a1474ed2b2ecb54c5931805f"); });`);
      script.id = 'f1f84a50a1474ed2b2ecb54c5931805f';
      const playerContainer = document.getElementsByClassName('header-content__player')[0];
      playerContainer.appendChild(script);
  }

  render() {
    return (
      <Header backgroundImage={headerBkg}>
        <div className='header-content video-monetization'>
        <div className='header-content__info-container'>
            <div className='header-content__info'>
              {/* <img className='header-content__logo' src={headerLogo} alt=""/> */}
              <div className='header-tag'>
                Video Monetization
              </div>
              <h1 className='header-content__title'>Maximize yield with a robust revenue engine</h1>
              <h2 className='header-content__description'>Powered by an integrated ad server and exchange, access premium demand while growing direct sales efforts.</h2>
            
              <Link to="playspace-contact"
                    spy={true}
                    smooth={true}
                    className='header-content__btn'>
                Get in touch
              </Link>
            </div>
          </div>
          <div className='header-content__player-container'>
            <div className='header-content__player-background'>
              <div className='header-content__player'>
              </div>
            </div>
          </div>
        </div>
      </Header>
    )
  }
}

export default PlayspaceHeader;