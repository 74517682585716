import React from 'react';
import Reason from "../Reason/Reason";

import styles from './ReasonsList.module.scss';

const reasons = [
  {
    title: 'The People',
    description: `At the heart of Connatix’s technology is our people. 
                  We’re a group of innovators, disruptors, techies, and storytellers.`
  },
  {
    title: 'The Tech',
    description: `There's an electric energy for our products, roadmap, and vision that motivates and truly inspires 
                  across offices.`
  },
  {
    title: 'The Space',
    description: `Having a great place to work is important! Our offices were designed to foster a creative, 
                  collaborative, and open energy.`
  },
  {
    title: 'The Mission',
    description: `Our mission is to help publishers create successful video experiences . Helping publishers succeed 
                  has been part of our mission since day one.`
  },
  {
    title: 'The Values',
    description: `At the foundation of Connatix are our core values, including speed, responsiveness, and a commitment 
                  to excellence.`
  },
  {
    title: 'The Balance',
    description: `Balance is key to a great workplace. In addition to a flexible vacation policy, 
                  you’ll often find employees breaking for a game of FIFA.`
  }
];

const ReasonsList = () => (
  <div className={styles['reasons-list']}>
    {reasons.map((reason, index) => (
      <Reason key={index} number={index + 1} title={reason.title} description={reason.description}/>
    ))}
  </div>

);

export default ReasonsList;